import React, {useCallback, useEffect, useMemo} from 'react';
import styled from "styled-components";
import RankingHeader from "./RankingHeader";
import RankingTable from "./RankingTable";
import RankingTableNavigator from "./RankingTable/RankingTableContent/RankingTableNavigator";
import {useHistory, useParams} from "react-router-dom";
import useQuery from "../../../common/hooks/useQuery";
import {useDispatch, useSelector} from "react-redux";
import {GET_RANKING_REQUEST} from "../../../store/reducer/ranking";
import {capitalizeLetter} from "../../../function";
import regionList from "../../../store/static_json/regionList";
import {ErrorBoundary} from "react-error-boundary";
import {useTranslation} from "react-i18next";
import RightRailAd from "../../ad/RightRailAd";
import LeftRailAd from "../../ad/LeftRailAd";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10vh;
  margin-top: 10px;
`;


const ContentWrapper = styled.div`
  width: 1080px;
  border-radius: 6px;
  background-color: ${props => props.theme.color.site_background_color};
  padding: 15px;
  position: relative;
  z-index: 1;
`;


const FlexAdBox = styled.div`
  flex: 1;
`;

const TopAdBox = styled.div`
`;


const WrapperBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`;

const SmallRail = styled.div`
  width: 300px;
  margin-bottom: 15px;
`;


const LeftSideWrapper = styled.div`
  width: 300px;
  height: 600px;
  position: sticky;
  top: 270px;

`;


const StickyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
`;


const RightSIdeStickyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
`;

const StickyBoxWrapper = styled.div`
  width: 340px;
  padding: 20px;
  position: relative;
`

const RankingContent = () => {
    const {t} = useTranslation();
    const query = useQuery();
    const dispatch = useDispatch();
    const history = useHistory();
    const {position, platform} = useParams();
    const {
        ranking,
        getRankingLoading,
        getRankingDone,
        getRankingError,
    } = useSelector((state) => state.ranking);
    const version = useSelector((state) => state.gameVersion.version);
    const currentPage = useMemo(() => Number(query.get('page')) > 0 ? Number(query.get('page')) : 1, [query.get('page')]);
    const rankingRegion = useMemo(() => regionList.find((data) => data.title.toLowerCase() === platform?.toLowerCase()), [platform]);

    const onClickToFist = useCallback(() => {
        query.set('page', "1");
        const url = `/ranking/${platform}/${position.toLowerCase()}?${query.toString()}`
        history.push(url);
    }, [currentPage, position, platform]);

    const onClickToEnd = useCallback(() => {
        query.set('page', ranking?.total_page?.toString());
        const url = `/ranking/${platform}/${position.toLowerCase()}?${query.toString()}`
        history.push(url);
    }, [currentPage, position, platform, ranking]);

    const onClickPrev = useCallback(() => {
        if (currentPage > 1) {
            query.set('page', (Number(currentPage) - 1).toString());
            const url = `/ranking/${platform}/${position.toLowerCase()}?${query.toString()}`
            history.push(url);
        }
    }, [currentPage, position, platform]);

    const onClickNext = useCallback(() => {
        if (currentPage < ranking?.total_page) {
            query.set('page', (Number(currentPage) + 1).toString());
            const url = `/ranking/${platform}/${position.toLowerCase()}?${query.toString()}`
            history.push(url);
        }
    }, [currentPage, position, ranking, platform]);

    const onClickGoToPage = useCallback((value) => {

        if (value <= ranking?.total_page) {

            query.set('page', value);
            const url = `/ranking/${platform}/${position.toLowerCase()}?${query.toString()}`
            history.push(url);
        }
    }, [ranking, position, platform]);

    useEffect(() => {
        dispatch({
            type: GET_RANKING_REQUEST,
            data: {
                platform: rankingRegion.title,
                page: currentPage,
                lane: capitalizeLetter(position),
            }
        })
    }, [position, currentPage, rankingRegion.title]);


    return (
        <Wrapper id={'ranking_content'}>
            <LeftRailAd/>

            <WrapperBox id={'ranking_content_wrapper'}>
                <TopAdBox id={'ranking_content_top_ad_box'} data-ad={'billboard'}/>
                <ContentWrapper>
                    {getRankingDone &&
                        <RankingHeader
                            time={ranking?.update_time}
                            challengerLp={ranking?.challenger_lp}
                            grandMasterLp={ranking?.grandmaster_lp}
                            rankingRegion={rankingRegion}
                            ranking={ranking}
                            currentLane={position}
                        />}

                    <ErrorBoundary>
                        <RankingTable
                            getRankingDone={getRankingDone}
                            version={version}
                            getRankingError={getRankingError}
                            ranking={ranking}
                            currentLane={position}
                            rankingRegion={rankingRegion}
                        />
                    </ErrorBoundary>
                    {getRankingDone &&
                        <RankingTableNavigator
                            onClickGoToPage={onClickGoToPage}
                            onClickToEnd={onClickToEnd}
                            onClickToFist={onClickToFist}
                            onClickPrev={onClickPrev}
                            onClickNext={onClickNext}
                            currentPage={currentPage}
                            lastPage={ranking?.total_page}
                        />
                    }
                </ContentWrapper>
                <TopAdBox id={'ranking_content_bottom_ad_box'}>

                </TopAdBox>
            </WrapperBox>
            <RightRailAd/>
        </Wrapper>
    );
};



export default RankingContent;
