import React from 'react';
import useQueueId from "./useQueueId";
import {useTranslation} from "react-i18next";

const UseMarqueeDescription = () => {
    const {t} = useTranslation()
    const {getMatchName} = useQueueId();


    const transDescription = (data) => {
        const descriptionNumber = Number(data.description);

        switch (descriptionNumber) {
            case 2:
                return t('news.news02', {
                    player1: data.name,
                    player2: data.versus,
                })
            case 3:
                return t('news.news03', {
                    gameMode: getMatchName(data.queue_id)
                })
            case 4:
                return t('news.news04', {
                    gameMode: getMatchName(data.queue_id)
                })
            case 5:
                return t('news.news05', {
                    grade: t('news.grade', {
                        count: Number(data.versus),
                        ordinal: true,
                    })
                })
            case 6:
                return t('news.news06', {
                    tier: data.versus
                })
            case 7:
                return t('news.news07')
            default:
                return ""
        }
    }


    return {
        transDescription
    }
};

export default UseMarqueeDescription;