import React, {useCallback, useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import SummarySummonerInfo from "./SummarySummonerInfo";
import AiTableContent from "./AiTableContent";
import KdaTable from "./KdaTable";
import DamageGraph from "./DamageGraph";
import CsInfo from "./CsInfo";
import WardInfo from "./WardInfo";
import ItemInfo from "./ItemInfo";
import {csPerMinute, isAramMap} from "../../../../../../../function";
import TableMultiKill from "./TableMultiKill";

const Wrapper = styled.tr`
  background-color: ${props => {
    let color = 'transparent';
    if (props?.isme) {
      if (props.win) color = props.theme.color.blue_200;
      if (!props.win) color = props.theme.color.red_200;
    } else {
      if (props.win) color = props.theme.color.blue_300;
      if (!props.win) color = props.theme.color.red_400;
    }

    if (props.hide) {
      if (props.win) color = props.theme.color.blue_300;
      if (!props.win) color = props.theme.color.red_400;
    }
    return color;
  }};
  border-top: 1px solid ${props => props.theme.color.default_border_color};
`;


const TableChild = styled.td`
  height: 42px;
  vertical-align: middle;
`;


const DamageWrapper = styled.div`
  width: 61px;
`;
const DamageBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;


const TableContent = ({
                          data,
                          rank,
                          isAce,
                          isMvp,
                          allDamage,
                          duration,
                          isme,
                          region,
                          onClickAi,
                          graphIndex,
                          gameType,
                          hide,
                          onChangeBuildIndex,
                          onClickRankGraph,
                          hoverScale
                      }) => {

    const setHms = useCallback((time) => {
        const hour = time > 3600 ? time / 3600 : 0;
        const min = time / 60;
        const sec = time % 60;
        return `${hour}:${min}:${sec}`;
    }, []);
    const theme = useTheme();

    const matchTimeStamp = useMemo(() => csPerMinute(data.final_stat_dict.cs, setHms(duration)), [duration, data])

    console.log(isme)
    return (
        <Wrapper isme={isme} win={data.is_win} hide={hide}>
            <TableChild>
                <SummarySummonerInfo
                    oldName={data?.summoner_name}
                    tag={data.riot_id_tag_line}
                    disableBar={true}
                    hide={hide}
                    smite={data.smite}
                    data={data}
                    region={region}
                    isme={hide ? false : isme}
                    tier={data.tier}
                    division={data.division}
                    level={data.final_stat_dict.champion_level}
                    champId={data.champion_id}
                    win={data.is_win}
                    summonerName={data?.riot_id_name}
                    puuId={data.puu_id}
                    spellFId={data.spell_id_dict.spell_1}
                    spellDId={data.spell_id_dict.spell_2}
                    perkSubStyle={data.rune_detail_dict.perk_sub_style}
                    perk={data.rune_detail_dict.perk_0}
                />
            </TableChild>
            <TableChild>
                <AiTableContent
                    puu_id={data.puu_id}
                    hoverScale={hoverScale}
                    onClickRankGraph={onClickRankGraph}
                    gameType={gameType}
                    checkAway={data.is_runaway}
                    data={data}
                    onClick={onClickAi}
                    isMvp={data.is_win && isMvp}
                    isAce={isAce}
                    aiScore={data.ai_score}
                    rank={rank}
                />
            </TableChild>
            <TableChild>
                <KdaTable
                    runaway={data.is_runaway}
                    kill={data.final_stat_dict.kills}
                    death={data.final_stat_dict.deaths}
                    assist={data.final_stat_dict.assists}
                />
            </TableChild>
            {graphIndex === 1 ?
                <TableChild>
                    <DamageBox>
                        <DamageWrapper>
                            <DamageGraph
                                reverse={true}
                                dmg={data.total_damage_dealt}
                                total={allDamage?.totaldmg}
                                color={theme.color.dark_200}
                                barColor={'#EF4D8A'}
                            />
                        </DamageWrapper>
                    </DamageBox>
                </TableChild> : null}
            {graphIndex === 0 ?
                <TableChild>
                    <DamageBox>
                        <DamageWrapper>
                            <DamageGraph
                                reverse={true}
                                dmg={data.total_damage_taken}
                                total={allDamage?.totalTaken}
                                color={theme.color.font_74}
                                barColor={'#7C8698'}
                            />
                        </DamageWrapper>
                    </DamageBox>
                </TableChild> : null}
            {isAramMap(gameType) ?
                <TableChild>
                    <TableMultiKill
                        doubleKill={data?.double_kills}
                        tripleKill={data?.triple_kills}
                        quadraKill={data?.quadra_kills}
                        pentaKill={data?.penta_kills}
                    />
                </TableChild> : null
            }
            {!isAramMap(gameType) ?
                <>
                    <TableChild>
                        <CsInfo
                            cs={data.final_stat_dict.cs}
                            duration={matchTimeStamp}
                        />
                    </TableChild>
                    <TableChild>
                        <WardInfo
                            bought={data.final_stat_dict.vision_wards_bought}
                            placed={data.final_stat_dict.wards_placed}
                            removed={data.final_stat_dict.wards_killed}
                        />
                    </TableChild>
                </> : null}
            <TableChild>
                <ItemInfo
                    onChangeBuildIndex={onChangeBuildIndex}
                    myMatch={data}
                    item={data.final_item_dict}
                />
            </TableChild>
        </Wrapper>
    );
};

const isEquals = (prev, next) => {
    return prev.champion_id === next.champion_id && prev.graphIndex === next.graphIndex && prev.hide === next.hide && prev.isme === next.isme;
}

export default React.memo(TableContent, isEquals);

