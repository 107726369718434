import React from 'react';
import styled from "styled-components";
import ChampionsAramHeader from "../../common/ChampionsAndAramHeader";
import useDispatchChampVersion from "../../../common/hooks/useDispatchChampVersion";
import AdComponent from "../../ad/AdComponent";
import {champion_center_content} from "../../../ad/bidrich";
import SponsorAD from "../../../ad/Tapin";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import CheckDataLayer from "../../DataLayer/CheckDataLayer";
import RightRailAd from "../../ad/RightRailAd";
import LeftRailAd from "../../ad/LeftRailAd";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;


const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: ${props => props.theme.color.site_background_color};
  border-radius: 6px;
  z-index: 1;
`;

const ContentDiv = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-bottom: 10vh;
  width: 1112px;
`;

const AdBox = styled.div`
  flex: 1;
`;

const TopBox = styled.div`
  width: 1080px;
  height: 250px;
  margin-top: 20px;
`;

const SmallRail = styled.div`
  width: 300px;
  margin-bottom: 15px;
`;


const LeftSideWrapper = styled.div`
  width: 300px;
  height: 600px;
`;



const StickyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;



const RightSIdeStickyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const StickyBoxWrapper = styled.div`
  min-width: 300px;
  padding-top: 20px;
  position: relative;
`;
const CenterContentBox = ({children, flexD, disableSponsor = false, disableAramHeader = false}) => {
    const {getChampionVersionsLoading, getChampionVersionsDone, data} = useDispatchChampVersion();
    const location = useLocation();
    const currentLang = useSelector((state) => state.translation.lang);
    if (getChampionVersionsLoading && !getChampionVersionsDone && data) return null;

    return (
        <CheckDataLayer>
            <AdComponent playwire={champion_center_content}>
                <Wrapper className={'champion_content'}>
                    <LeftRailAd/>
                    <ContentWrapper>
                        <TopBox id={'champion_content_top_ad_box'} data-ad={'billboard'}>

                        </TopBox>
                        <CenterDiv className={'champion_content_wrapper'}>
                            {!disableAramHeader && <ChampionsAramHeader/>}
                            {!disableSponsor && <SponsorAD/>}
                            <ContentDiv flexD={flexD}>
                                {children}
                            </ContentDiv>
                        </CenterDiv>
                        <TopBox id={'champion_content_bottom_ad_box'}>

                        </TopBox>
                    </ContentWrapper>
                    <RightRailAd/>
                </Wrapper>
            </AdComponent>
        </CheckDataLayer>
    );
};




export default CenterContentBox;
