import React from 'react';
import styled from "styled-components";
import {FlexBox} from "../ui/styled";

const Dot = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props.theme.color.dark_100;
    return color;
  }};
  opacity: ${props => {
    let opacity = '0';
    if (props.selected) opacity = '1';
    return opacity;
  }};
`;

const AccountFilterText = styled.span`
  font-size: 14px;
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props.theme.color.dark_100;
    return color;
  }};
  line-height: 20px;
`;

const FilterBox = styled(FlexBox)`
  font-weight: 500;
  cursor: pointer;

  &:hover {
    ${Dot} {
      background-color: ${props => {
        let color = props.theme.color.dark_200;
        if (props.selected) color = props.theme.color.dark_100;
        return color;
      }};
      opacity: ${props => {
        let opacity = '0.5';
        if (props.selected) opacity = '1';
        return opacity;
      }};
    }

    ${AccountFilterText} {
      color: ${props => {
        let color = props.theme.color.dark_200;
        if (props.selected) color = props.theme.color.dark_100;
        return color;
      }};
    }
  }
`;
const DotListItem = ({margin, selected, onClick, title}) => {
    return (
        <FilterBox margin={margin} selected={selected} onClick={onClick}>
            <Dot selected={selected}/>
            <AccountFilterText selected={selected}>{title}</AccountFilterText>
        </FilterBox>
    );
};

export default DotListItem;