import React, {Suspense, useMemo, useState} from 'react';
import {useSuspenseMarqueeApi} from "../../common/hooks/api/useMarqueeAPI";
import styled from "styled-components";
import {Footer, Header, Loading} from "../common";
import CenterContentBox from "../Champions/ContentWrapper";
import DotListItem from "../list/dotListItem";
import {useTranslation} from "react-i18next";
import {FlexBox} from "../ui/styled";
import NewsChat from "../news/newsChat";

const MarqueeContentText = styled.span`
  font-weight: 400;
  color: ${props => props.theme.color.dark_200};
  font-size: 12px;
`;
const FullBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  top: 66px;
`;
const MarqueeBold = styled.span`
  font-weight: 600;
  color: ${props => props.theme.color.dark_100};
  font-size: 12px;
  margin-left: 4px;
`;
const Wrapper = styled.div`
`;
const ContentWrapper = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const NewsList = () => {
    return (
        <Wrapper>
            <Header/>
            <ContentWrapper>
                <Suspense fallback={<Loading/>}>
                    <CenterContentBox>
                        <NewsContent/>
                    </CenterContentBox>
                </Suspense>
            </ContentWrapper>
            <Footer/>
        </Wrapper>
    );
};


const NewsWrapper = styled.div`
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  background-color: ${props => props.theme.color.site_main_color};
  flex: 1;
  padding: 20px;
`;
const NewsInsideBox = styled.div`
  flex: 1;
  background-color: ${props => props.theme.color.white_201};
  border-radius: 6px;
  padding: 20px;
`;

const MoreButton = styled.button`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  background: ${props => props.theme.color.site_main_color};
  font-size: 16px;
  color: ${props => props.theme.color.dark_200};
  text-align: center;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;

  &:hover {
    background-color: ${props => props.theme.color.white_201};
  }
`;
const NewsContentWrapper = styled.div`
  flex: 1;
  margin: 10px;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;
const Title = styled.span`
  font-size: 26px;
  font-weight: bold;
  line-height: 31px;
`;
const ExtraText = styled.p`
  color: ${props => props.theme.color.dark_200};
  margin-top: 2px;
  line-height: 17px;
  margin-bottom: 10px;
`;
const NewsContent = () => {
    const {t} = useTranslation()
    const {list} = useSuspenseMarqueeApi({limit: 'all'});
    const [selected, setSelected] = useState(0);
    const [pagination, setPagination] = useState(1);


    const onChangeSelected = (value) => {
        setSelected(value)
        setPagination(1)
    }


    const filteredList = useMemo(() => {
        try {
            if (!Array.isArray(list)) {
                return [];
            }
            if (selected === 0) {
                return list;
            }
            if (selected === 1) {
                return list.filter((data) => data.status === 'pro');
            }
            if (selected === 2) {
                return list.filter((data) => data.status === 'Streamer');
            }
            if (selected === 3) {
                return list.filter((data) => data.female === 1);
            }

            if (selected === 4) {
                return []
            }
        } catch (e) {
            return [];
        }
    }, [selected, pagination, list]);

    return (
        <NewsContentWrapper>
            <TitleWrapper>
                <Title>{t("news.title")}</Title>
            </TitleWrapper>
            <ExtraText>{t('news.titleExtra')}</ExtraText>
            <FlexBox alignItems={'flex-start'}>
                <FilterNews onChangeSelected={onChangeSelected} selected={selected}/>
                <NewsChat filteredList={filteredList} pagination={pagination} setPagination={setPagination}/>
            </FlexBox>

        </NewsContentWrapper>
    );
}



const FilterNewsWrapper = styled.div`
  min-width: 200px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  background-color: ${props => props.theme.color.site_main_color};
  padding: 20px;
  margin-right: 10px;
`;
const FilterNews = ({selected, onChangeSelected}) => {
    const {t} = useTranslation()
    return (
        <FilterNewsWrapper>
            <DotListItem
                margin={'0 0 15px 0'} selected={selected === 0} onClick={() => onChangeSelected(0)}
                title={t("tabs.total")}
            />

            <DotListItem
                margin={'0 0 15px 0'}
                selected={selected === 1} onClick={() => onChangeSelected(1)}
                title={t("autoComplete.pro")}
            />

            <DotListItem
                margin={'0 0 15px 0'}
                selected={selected === 2} onClick={() => onChangeSelected(2)}
                title={t("autoComplete.streamer")}
            />

            <DotListItem
                selected={selected === 3} onClick={() => onChangeSelected(3)}
                title={t('autoComplete.femaleGamer')}
            />
            {/*<DotListItem*/}
            {/*    selected={selected === 4} onClick={() => onChangeSelected(4)}*/}
            {/*    title={t('autoComplete.deeplolNews')}*/}
            {/*/>*/}
        </FilterNewsWrapper>
    )
}

export default NewsList;