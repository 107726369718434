import React, {useMemo} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import MarqueeContent from "../marquee/marqueeContent";

const NewsWrapper = styled.div`
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  background-color: ${props => props.theme.color.site_main_color};
  flex: 1;
  padding: 20px;
`;
const NewsInsideBox = styled.div`
  flex: 1;
  background-color: ${props => props.theme.color.white_201};
  border-radius: 6px;
  padding: 20px;
`;

const MoreButton = styled.button`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  background: ${props => props.theme.color.site_main_color};
  font-size: 16px;
  color: ${props => props.theme.color.dark_200};
  text-align: center;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;

  &:hover {
    background-color: ${props => props.theme.color.white_201};
  }
`;

const NewsChat = ({pagination,setPagination,filteredList,}) => {
    const {t}  = useTranslation();
    const pageCount = 20;
    const sliceArray = (array) => {
        return array.slice(0, pagination * 20);
    }

    const pageItemCount = useMemo(() => pagination * pageCount, [pagination]);

    const leftPages = useMemo(() => {
        const totalPage = Math.ceil(filteredList.length / 20);
        return totalPage - pagination;
    }, [pageItemCount, filteredList]);
    return (
        <NewsWrapper>
            <NewsInsideBox>
                <NewsMap list={sliceArray(filteredList)}/>
            </NewsInsideBox>
            {leftPages > 0 &&
                <MoreButton onClick={() => setPagination(prev => prev + 1)}>
                    {t('summoner.more')}
                </MoreButton>}
        </NewsWrapper>
    );
};



const NewsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & + & {
    margin-top: 10px;
  }
`;
const NewsMap = ({list}) => {

    if (list.length === 0) return null
    return list.map((data, index) => {
        return (
            <NewsBox key={index}>
                <MarqueeContent data={data}/>
            </NewsBox>
        )
    })
}

export default NewsChat;