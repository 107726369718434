import React from 'react';
import {openToSummonerMatch, openToSummonerStory} from "../../function";
import InfluIcon from "../summoner/Profile/InfluInfo/InfluIcon";
import useTimeHook from "../../common/hooks/useTimeHook";
import styled, {useTheme} from "styled-components";
import {ColumnBox, FlexBox} from "../ui/styled";
import {useTranslation} from "react-i18next";
import useMarqueeDescription from "../../common/hooks/useMarqueeDescription";

const MarqueeContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: ${props => props.marginRight}px;

`;
const MarqueeContentText = styled.div`
  padding: 6px 10px;
  font-weight: 400;
  color: ${props => props.theme.color.dark_200};
  font-size: 12px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 6px;

  cursor: pointer;

  &:hover {
    border: 1px solid ${props => props.theme.color.green_100};

    .watch-game {
      text-decoration: underline;
    }
  }
`;
const FullBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  top: 66px;
`;
const MarqueeBold = styled.span`
  font-weight: 600;
  color: ${props => props.theme.color.dark_100};
  font-size: 12px;
  margin-left: 4px;
`;

const TimeBox = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
  margin-left: 5px;
`;
const ProfileImage = styled.img`
  border-radius: 100%;
`;
const WatchGameSpan = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.color.dark_300};
  font-size: 12px;
  margin-top: 5px;

`;
const ArrowImg = styled.img`
  margin-left: 4px;
`;
const MarqueeContent = ({data, marginRight}) => {
    const {lessMinWithIntl} = useTimeHook()
    const theme = useTheme();
    const {t} = useTranslation()
    const {transDescription} = useMarqueeDescription();


    return (
        <MarqueeContentWrapper marginRight={marginRight}>
            <ProfileImage
                src={`${process.env.REACT_APP_ROOT_IMG}${data.status?.toLowerCase()}/${data.status === 'pro' ? data.championship_name : data.name}.png`}
                onError={(e) => e.target.src = '/images/img_unknown.svg'}
                width={36}
                height={36}
                alt={''}
            />
            <ColumnBox margin={'0 0 0 10px'}>
                <FlexBox>
                    <InfluIcon status={data.status}/>
                    <MarqueeBold>
                        {`${data.team_al} ${data.name}`}
                    </MarqueeBold>
                </FlexBox>

                <FlexBox alignItems={'flex-end'} margin={'5px 0 0 0'}>
                    <MarqueeContentText onClick={() => {
                        if (data.match_id) {
                            openToSummonerMatch({
                                name: `${data.riot_name}-${data.riot_tag}`,
                                region: data.platform_id,
                                matchId: data.match_id
                            })
                        } else {
                            openToSummonerStory({name: `${data.riot_name}-${data.riot_tag}`, region: data.platform_id})
                        }
                    }}>
                        <span>{transDescription(data)}</span>
                        {data.match_id &&
                            <WatchGameSpan className={'watch-game'}>
                                {t('news.watchGame')}
                                <ArrowImg src={`${theme.icon.path}/icn_expand_arrow_m_gray.svg`} width={8} height={8}
                                          alt={''}/>
                            </WatchGameSpan>}

                    </MarqueeContentText>
                    <TimeBox>
                        ({lessMinWithIntl(data.start_timestamp * 1000,)})
                    </TimeBox>
                </FlexBox>
            </ColumnBox>
        </MarqueeContentWrapper>
    );
};

export default MarqueeContent;