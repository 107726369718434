import React, {useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';

import {scoreColor} from '../../function';
import {useTranslation} from "react-i18next";
import {MatchDetailContainer} from "../../styled/SummonerStyle";
import ChampionImageWithLane from "../common/ChampionImageWithLane";
import ScoreWrapper from "./DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";
import styled from "styled-components";
import {Loading} from "../common";
import {ChartAnalysis, ChartScore} from "../charts";
import useDetailChartTab from "../../common/hooks/useDetailChartTab";


const TeamBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  flex: 1;
  height: 57px;

  & + & {
    margin-left: 54px;
    justify-content: flex-start;
  }

  .react-tabs__tab {
    z-index: 9;
    margin: 0 9px;

    &--selected {
      .imgDiv {
        border: 3px solid #F3A634;
      }
    }
  }

  .imgChamp {
    width: 42px;
    height: 42px;
  }

  .imgDiv {
    border-radius: 4px;
  }

  list-style-type: none;
`;

const Bar = styled.div`
  position: absolute;
  top: 15px;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 18px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
  color: #f6f6f6;
  border-radius: 0 100px 100px 0;
  background-color: ${(props) => {
    let color = props.theme.color.red_100;
    if (props.isWin) color = props.theme.color.blue_100;
    return color;
  }};
`;

const BarReverse = styled(Bar)`
  border-radius: 100px 0 0 100px;
  justify-content: flex-end;
`;

const CustomTabList = styled(TabList)`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 4px;
`;

const ScoreSpan = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
`;

const CustomTabs = styled(Tabs)`
  background-color: ${props => props.theme.color.site_main_color}.react-tabs__tab-panel {
    background-color: ${props => props.theme.color.site_main_color}
  }
`;


const ChampImageWrapper = styled.div`
  height: 57px;
  width: 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .imgChamp {
    width: 42px !important;
    height: 42px !important;

  }
`;
const DetailTab2 = ({match, myMatch}) => {
        const {t} = useTranslation();
        // const [ratingAI, setRatingAI] = useState([]);
        const {blueTeam, ratingAI, redTeam, defaultTabIndex, originArr} = useDetailChartTab({
            match,
            myMatch,
        })


        const [tabIndex, setTabIndex] = useState(defaultTabIndex);


        return (
            <MatchDetailContainer>
                {ratingAI.length !== 0 ? (
                        <CustomTabs selectedIndex={tabIndex}>
                            <CustomTabList>
                                <TeamBox>
                                    <Bar
                                        isWin={blueTeam[0]?.is_win}>{blueTeam[0]?.is_win ? t('summoner.side.winTeam') : t('summoner.side.loseTeam')}</Bar>
                                    {/*<img src={'images/ico_trophy.svg'} width={18} height={19}*/}
                                    {/*     alt={""}/>*/}
                                    {blueTeam.map((data, index) => {
                                        return (<Tab>
                                            <ChampImageWrapper onClick={() => setTabIndex(index)}>
                                                <ChampionImageWithLane champion_id={data.champion_id} disabled/>
                                                <ScoreSpan>{scoreColor(Math.trunc(data?.final_stat_dict?.ai_score))}</ScoreSpan>

                                            </ChampImageWrapper>
                                        </Tab>)
                                    })}

                                </TeamBox>


                                <TeamBox>
                                    {/*<img src={'images/ico_trophy.svg'} width={18} height={19}*/}
                                    {/*     alt={""}/>*/}
                                    <BarReverse
                                        isWin={redTeam[0]?.is_win}>{redTeam[0]?.is_win ? t('summoner.side.winTeam') : t('summoner.side.loseTeam')}</BarReverse>
                                    {redTeam.map((data, index) => {
                                        return (<Tab>
                                            <ChampImageWrapper onClick={() => setTabIndex(index + 5)}>
                                                <ChampionImageWithLane champion_id={data.champion_id} disabled/>
                                                <ScoreSpan>{scoreColor(Math.round(data?.final_stat_dict?.ai_score))}</ScoreSpan>
                                            </ChampImageWrapper>
                                        </Tab>)
                                    })}

                                </TeamBox>

                            </CustomTabList>

                            {
                                blueTeam.map((data, index) => {
                                    return (
                                        <CustomTabPanel myMatch={myMatch} originArr={originArr} info={data} key={index}
                                        >
                                            {match}
                                        </CustomTabPanel>
                                    )
                                })
                            }
                            {redTeam.map((data, index) => {
                                return (
                                    <CustomTabPanel myMatch={myMatch} originArr={originArr} info={data} key={index}
                                    >
                                        {match}
                                    </CustomTabPanel>
                                )
                            })
                            }

                        </CustomTabs>
                    ) :
                    (
                        <Loading/>
                    )
                }
            </MatchDetailContainer>
        )
            ;
    }
;

// 시간대별 분석 탭 버튼
const CustomTab = ({children, info, myMatch, AiMemo, ...otherProps}) => {


    const {t} = useTranslation();
    return (
        <Tab {...otherProps}>
            <div className="rating">
                {AiMemo[0].riot_id_name === info.riot_id_name && <img src={'images/ico_best_player01.svg'}/>}
                {AiMemo[5].riot_id_name === info.riot_id_name && <img src={'images/ico_best_player04.svg'}/>}
                {info.final_stat_dict.ai_score_rank + t('summoner.grade', {
                    count: info.final_stat_dict.ai_score_rank,
                    ordinal: true,
                })}
            </div>
            <div className="champ">
                <ChampionImageWithLane champion_id={info.champion_id} disabled={true}/>
            </div>
            <div className="score">
                <ScoreWrapper
                    noneClickable={true}
                    score={info.ai_score}
                    fontSize={16}

                    paddingHorizontal={5}
                />
            </div>

            <div className="chart">
                <ChartScore
                    data={children.time_analysis}
                    myMatch={info}
                    width={110}
                    height={40}
                    label={false}
                />
            </div>
        </Tab>
    )
};
CustomTab.tabsRole = 'Tab';

// 시간대별 분석 탭 패널
const CustomTabPanel = ({children, info, myMatch, originArr, ...otherProps}) => (
    <TabPanel {...otherProps}>
        <ChartAnalysis
            inferenceData={children.time_analysis}
            info={info}
            myMatch={myMatch}
            originArr={originArr}
        />
    </TabPanel>
);
CustomTabPanel.tabsRole = 'TabPanel';

export default DetailTab2;
