import React, {memo} from 'react';
import UseRailScrollEvent from "../../common/hooks/useScrollEvent";
import styled from "styled-components";

const SmallRail = styled.div`
  width: 300px;
  margin-bottom: 15px;
`;


const LeftSideWrapper = styled.div`
  width: 300px;
  height: 600px;
`



const StickyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;



const StickyBoxWrapper = styled.div`
  min-width: 300px;
  padding-top: 20px;
  position: relative;
`;

const LeftRailAd = memo(() => {
    const ref = UseRailScrollEvent();
    return (
        <StickyBoxWrapper>
            <StickyBox ref={ref}>
                <SmallRail data-ad={'left-rail-1'}/>
                <LeftSideWrapper  data-ad={"left-rail-2"}/>
            </StickyBox>
        </StickyBoxWrapper>
    )
})

export default LeftRailAd;