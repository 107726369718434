import React from 'react';
import {isArena, isSwarm} from "../../../../function";
import ArenaGameSummary from "../ArenaGameAnalytics";
import GameAnalytics from "../GameAnalytics";
import SwarmGameSummary from "../SwarmGameSummary";

const RenderAnalytics = ({queueId, match, myMatch, hide, region, onChangeBuildIndex, isUrf,onClickRankGraph}) => {


    if (isSwarm(queueId)) {
        return (
            <SwarmGameSummary match={match} myMatch={myMatch} hide={hide} region={region}/>
        )
    }

    if (isArena(queueId)) {
        return <ArenaGameSummary
            match={match}
            myMatch={myMatch}
            hide={hide}
            region={region}
        />
    }


    return (
        <GameAnalytics
            onClickRankGraph={onClickRankGraph}
            onChangeBuildIndex={onChangeBuildIndex}
            hide={hide}
            isUrf={isUrf}
            region={region}
            match={match}
            myMatch={myMatch}
        />
    );
};

export default RenderAnalytics;