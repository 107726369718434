export const light = {
    theme: 'light',
    yellow_100: '#F19000',
    site_background_color: '#F8F9FD',
    site_main_color: '#FFFFFF',
    dark_100: '#354052',
    dark_200: '#66748F',
    dark_300: '#AFB2BF',
    dark_300_80: 'rgba(175, 178, 191,0.8)',
    gray_100: '#E6E6F6',
    gray_200: "#E1E6F2",
    gray_300: "#69788D",
    white_100: '#F9F9FA',
    white_200: '#F1F3F9',
    white_201: '#F1F3F9',
    white_300: '#fff',
    green_100: '#1DAAAA',
    green_200: '#E2F6F4',
    red_100: '#EB5353',
    red_200: '#FFDCDC',
    red_300: '#C24A4A',
    red_400: '#FFF0EE',
    red_500: '#EB6053',
    red_600: '#C40C23',
    red_700: '#FAD2DB',
    blue_100: '#4C92FC',
    blue_200: '#CFE4FF',
    blue_300: '#E7F0FC',
    blue_400: '#3571E9',
    green_300: '#259A7E',
    gray_400: '#69788D',
    purple_100: '#6B78B5',
    purple_200: '#B7BEDE',
    purple_300: '#EDECF5',
    purple_400: '#DBD6FE',
    gradient_hard: "linear-gradient(90deg, #E8FAA8 0%, #C2FAD2 23%, #A0E5F7 42%, #A7DDF9 55%, #D7BDFA 81%, #F9D0F2 100%)",
    default_border_color: "#C3D1DC",
    default_border_color_op1: "rgba(195, 209, 220, 0.5)",
    tier_cut_iron: "#514330",
    tier_cut_bronze: "#815639",
    tier_cut_silver: "#626B8A",
    tier_cut_gold: "#A17716",
    tier_cut_platinum: "#2A8492",
    tier_cut_emerald: "#21855A",
    tier_cut_diamond: "#0063BB",
    tier_cut_master: "#8D41A9",
    tier_cut_grandmaster: "#C23449",
    tier_cut_challenger: "#FD8A12",
    tier_cut_1st: "linear-gradient(41deg, #687AF3 0%, #1D8DB3 53%, #21B7CF 100%)",
    tier_cut_text_iron: "#514330",
    tier_cut_text_bronze: "#815639",
    tier_cut_text_silver: "#626B8A",
    tier_cut_text_gold: "#A17716",
    tier_cut_text_platinum: "#2A8492",
    tier_cut_text_emerald: "#21855A",
    tier_cut_text_diamond: "#0063BB",
    tier_cut_text_master: "#8D41A9",
    tier_cut_text_grandmaster: "#C23449",
    tier_cut_text_challenger: "#FD8A12",
    tier_cut_text_1st: "#155C78",
    tier_cut_bar_master: "#8D41A9",
    tier_cut_bar_grandmaster: "#C23449",
    tier_cut_bar_challenger: "#FD8A12",
}


export const dark = {
    theme: 'dark',
    yellow_100: '#F19000',
    blue_400: '#3571E9',
    green_300: '#259A7E',
    gray_400: '#ADB5BF',
    site_background_color: '#191919',
    site_main_color: '#262626',
    dark_100: '#EEEEEE',
    dark_200: '#B2B2C2',
    dark_300: "#666666",
    dark_300_80: 'rgba(102, 102, 102,0.8)',
    gray_100: "#151515",
    gray_200: "#303030",
    green_100: '#53C6AA',
    green_200: '#2C3A3A',
    gray_300: "#ADB5BF",
    white_100: '#202020',
    white_200: '#363636',
    white_201: '#1E1E1E',
    white_300: '#000',
    red_100: '#EB5353',
    red_200: '#682B2E',
    red_300: '#C24A4A',
    red_400: '#4F2C2F',
    red_500: '#EB6053',
    red_600: '#C41023',
    red_700: '#623A43',
    blue_100: '#4C92FC',
    blue_300: '#263253',
    blue_200: '#2A4184',
    purple_100: '#6B78B5',
    purple_200: '#47474D',
    purple_300: '#151515',
    purple_400: '#433E66',
    gradient_hard: "linear-gradient(90deg, #17254A 0%, #224366 23%, #402C8D 42%, #7F3B97 55%, #AF6346 81%, #891A39 100%);",
    default_border_color: "#3C3C3C",
    default_border_color_op1: "rgba(60, 60, 60, 0.5)",
    tier_cut_iron: "#514330",
    tier_cut_bronze: "#815639",
    tier_cut_silver: "#626B8A",
    tier_cut_gold: "#A17716",
    tier_cut_platinum: "#2A8492",
    tier_cut_emerald: "#21855A",
    tier_cut_diamond: "#0063BB",
    tier_cut_master: "#8D41A9",
    tier_cut_grandmaster: "#C23449",
    tier_cut_challenger: "#EE7A00",
    tier_cut_1st: "linear-gradient(41deg, #687AF3 0%, #1D8DB3 53%, #21B7CF 100%)",
    tier_cut_text_iron: "#A7A097",
    tier_cut_text_bronze: "#C0AA9B",
    tier_cut_text_silver: "#B7BBCB",
    tier_cut_text_gold: "#DBC798",
    tier_cut_text_platinum: "#97CBC4",
    tier_cut_text_emerald: "#7FD6A7",
    tier_cut_text_diamond: "#A7CDF2",
    tier_cut_text_master: "#D3B3EA",
    tier_cut_text_grandmaster: "#EBB4C4",
    tier_cut_text_challenger: "#EE7A00",
    tier_cut_text_1st: "#21B7CF",
    tier_cut_bar_master: "#D3B3EA",
    tier_cut_bar_grandmaster: "#EBB4C4",
    tier_cut_bar_challenger: "#EE7A00",
}


export const lightIcon = {
    championPath: "/images/champion",
    path: "/images",
    top: '/images/icon_s_position_top.svg',
    bottom: '/images/icon_s_position_bot.svg',
    jungle: '/images/icon_s_position_jungle.svg',
    supporter: '/images/icon_s_position_supporter.svg',
    mid: '/images/icon_s_position_middle.svg',
}

export const darkIcon = {
    path: "/images/dark",
    championPath: "/images/champion/dark",
    top: '/images/icon_s_position_top.svg',
    bottom: '/images/icon_s_position_bot.svg',
    jungle: '/images/icon_s_position_jungle.svg',
    supporter: '/images/icon_s_position_supporter.svg',
    mid: '/images/icon_s_position_middle.svg',
}
