import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {useQuery, useQueryClient, useSuspenseQuery} from "@tanstack/react-query";
import axios from "axios";
import {getRegion} from "../../../function";


const queryFn = async ({currentLang, limit, puu_id}) => {
    try {
        const url = new URL(`${process.env.REACT_APP_NEW_API}summoner/strm_pro_description`);
        url.searchParams.set('langauge', currentLang === 'KOR' ? 'kr' : 'en')
        url.searchParams.set('limit', limit ? limit : 'all')
        url.searchParams.set('puu_id', puu_id ? puu_id : "")
        const result = await axios.get(url.toString());
        return result?.data?.description_list ?? [];
    } catch (e) {
        return [];
    }
}

const getSingleMatch = (action) => {
    const changeRegion = getRegion(action.region);
    const url = `${process.env.REACT_APP_CACHE_API}match/match-cached?match_id=${action.match_id}&platform_id=${changeRegion}`;
    return axios.get(url).then((res) => res.data).catch((error) => console.log(error));
}


export const useStoryApi = ({limit, puu_id, region}) => {
    const currentLang = useSelector((state) => state.translation.lang);
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: [puu_id, currentLang === 'KOR' ? 'kr' : 'en','story'],
        queryFn: async () => {
            const marquee = await queryFn({puu_id, limit, currentLang});
            // const matchIdList = marquee.map((data) => data.match_id);
            // const matchList = await Promise.all(matchIdList.map((item) => {
            //     const queryData = queryClient.getQueryData(['match', item]);
            //     if (queryData) {
            //         return queryData;
            //     }
            //     if (item) {
            //         const match = getSingleMatch({match_id: item, region: region}).then((res) => {
            //             queryClient.setQueryData(['match',item],res)
            //             return res;
            //         })
            //         return match
            //     }
            //     return null
            // // }));
            // const combinedList = marquee.map((data, index) => {
            //     return {
            //         ...data,
            //         match: matchList[index]
            //     }
            // })
            // const filterNullMatchList = matchList.filter((data) => data !== null);
            return {list: marquee}
        },
    })


    const matchList = useMemo(() => {
        const list = query?.data?.list ?? [];

        return {
            list
        }
    }, [query.data])

    return {matchList};
};


export const useSuspenseMarqueeApi = ({limit, puu_id}) => {
    const currentLang = useSelector((state) => state.translation.lang);

    const query = useSuspenseQuery(
        {
            queryKey: ['marquee', currentLang === 'KOR' ? 'kr' : 'en', limit ? String(limit) : 'all', puu_id ? puu_id : ""],
            queryFn: async () => await queryFn({puu_id, limit, currentLang}),
            staleTime: Infinity
        })


    const list = useMemo(() => {
        if (!query?.data) {
            return []
        }
        return query.data
    }, [query.data])

    return {query, list};
}
