import React, {useMemo} from 'react';
import _, {isNaN} from "lodash";

const UseDetailChart = ({inferenceData, info}) => {

    console.log(info)
    let side = info.side === 'BLUE' ? 'B_' : 'R_';

    let lane = '';
    switch (info.position) {
        case 'Top':
            lane = 'Top';
            break;
        case 'Jungle':
            lane = 'Jun';
            break;
        case 'Middle':
            lane = 'Mid';
            break;
        case 'Bot':
            lane = 'Bot';
            break;
        case 'Supporter':
            lane = 'Sup';
            break;
        default:
            break;
    }
    const position = side + lane;

    const concatEvent = useMemo(() => {
        try {
            const dictObj = inferenceData?.concat_events_dict;
            if (typeof dictObj === 'object') {
                return Object.values(dictObj).map((obj) => {
                    const result = [];
                    Object.entries(obj).forEach((data) => {
                        if (!isNaN(Number(data[0]))) {
                            result.push(data[1]);
                        }
                    })
                    return result;
                });
            }
            return [];
        } catch (e) {
            console.log(e)
            return []
        }
    }, [inferenceData]);


    const eventList = useMemo(() => {
        try {
            const baseList = inferenceData?.concat_participants_dict[position];
            const copyArray = [...baseList];
            concatEvent.forEach((data, index) => {
                const allEvent = data.find((item) => {
                    return item.event.type === 'Monster' || item.event.type === 'Tower'
                });
                if (allEvent) {
                    copyArray.push(index);
                }
            });
            return _.uniq(copyArray);
        } catch (e) {
            console.log(e)
            return []
        }
    }, [inferenceData, concatEvent]);


    const personalEventMemo = useMemo(() => {
        try {
            if (Array.isArray(concatEvent) && concatEvent.length > 0) {
                if (Array.isArray(eventList) && eventList.length > 0) {
                    return concatEvent.filter((data, index) => {
                        return eventList.find((number) => Number(number) === index)
                    });
                }
            }
        } catch (e) {
            console.log(e)
            return [];
        }
    }, [concatEvent, position, eventList]);


    const originalEvent = useMemo(() => {
        try {
            const concat = Object.values(inferenceData.concat_events_dict);
            return concat.filter((data, index) => eventList.find((number) => Number(number) === index));
        } catch (e) {

            return []
        }

    }, [inferenceData, position, eventList]);

    const detailEvent = useMemo(() => {
        try {
            if (Array.isArray(personalEventMemo) && personalEventMemo.length > 0) {
                return personalEventMemo.reduce((sum, cur) => {
                    cur.forEach((data) => sum.push(data));
                    return sum;
                }, []);
            }
            return [];
        } catch (e) {

            return []
        }
    }, [personalEventMemo]);


    const personalScoreMemo = useMemo(() => {
        try {
            const score = Object.values(inferenceData.concat_seq_dict);
            return score.filter((data, index) => eventList.find((number) => Number(number) === index));
        } catch (e) {

            return []
        }
    }, [inferenceData, position, eventList]);


    return {
        modalIndex: 0,
        eventArr: personalEventMemo,
        originalEvent,
        detailEvent,
        concatEvent: personalEventMemo,
        aiScoreArr: personalScoreMemo
    }

};

export default UseDetailChart;