import React, {useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import useTimeHook from "../../../common/hooks/useTimeHook";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  & + & {
    margin-left: 26px;
  }
`;
const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
`;

const GreenTitle = styled.span`
  color: ${props => props.theme.color.green_100};
  font-size: 20px;
  font-weight: bold;
  margin-right: 8px;
`;

const BlackTitle = styled.span`
  color: ${props => props.theme.color.dark_100};
  font-size: 20px;
  font-weight: bold;
`;

const ExtraWrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ExtraText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
  margin-right: 2px;
  &:hover {
    text-decoration: underline;
  }
`;
const ContentBox = styled.div`
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 10px;
  padding: 15px;
`;
const HomeNoticeBoard = ({title, list,onClickMore}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const listMemo = useMemo(() => {
        try {
            if (Array.isArray(list)) {
                return list;
            }
            return [];
        } catch (e) {
            return [];
        }
    }, [list]);

    return (
        <Wrapper>
            <TitleBox>
                <Title>
                    <GreenTitle>{title}</GreenTitle>
                    <BlackTitle>{t('notice.title')}</BlackTitle>
                </Title>
                <ExtraWrapper onClick={onClickMore}>
                    <ExtraText>{t("summoner.moreChamp")}</ExtraText>
                    <img src={`${theme.icon.path}/icn_expand_arrow_m.svg`} width={8} height={8} alt={''}/>
                </ExtraWrapper>
            </TitleBox>
            <ContentBox>
                {listMemo.map((data, index) => {
                    return (
                        <HomeNoticeItem title={data.title}  time={data.datetime} url={data.url}
                                        key={index}/>
                    )
                })}
            </ContentBox>
        </Wrapper>
    );
};


const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & + & {
    margin-top: 15px;
  }
`;
const TagWrapper = styled.div`
  padding: 3px 7px;
  border-radius: 6px;
  color: ${props => props.theme.color.dark_200};
  background: ${props => props.theme.color.white_201};
  font-weight: 500;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const TitleWrapper = styled.div`
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
const DateWrapper = styled.div`
  color: ${props => props.theme.color.dark_200};
`;
const HomeNoticeItem = ({tag, title, time, url}) => {
    const {lessMinWithIntl} = useTimeHook()
    const currentLang = useSelector((state) => state.translation.lang);


    return (
        <ItemWrapper>
            <ContentWrapper>
                <TitleWrapper onClick={() => window.open(url)}>
                    {title}
                </TitleWrapper>
            </ContentWrapper>
            <DateWrapper>
                {lessMinWithIntl(new Date(time), currentLang)}
            </DateWrapper>
        </ItemWrapper>
    )
}

export default HomeNoticeBoard;