import React, {memo, Suspense, useCallback, useEffect, useMemo, useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation, useParams} from 'react-router-dom';
import {isBrowser, isMobile} from 'react-device-detect';
import NotFound from './components/NotFound';
import GlobalStyles from './styled/GlobalStyles';
import i18nInit from "./i18n";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {changeVersion, CLOUDFLARE_VERSION} from "./store/modules/gameVersion";
import axios from "axios";
import {imageStore} from "./store/modules/imageStore";
import ChampionsDetail from "./components/ChampionsDetail";
import Champions from "./components/Champions";
import styled, {ThemeProvider} from "styled-components";
import Ranking from "./components/Ranking";
import Aram from "./components/Aram";
import Balance from "./components/balance";
import {getDataDragonRegion} from "./i18n/languages";
import {translation} from "./store/modules/translation";
import Mastery from "./components/mastery";
import {dark, darkIcon, light, lightIcon} from "./common/theme";
import SpectateLivePro from "./components/Spectate/live/pro";
import {Footer, Loading, ToMobile} from "./components/common";
import {Multi} from "./components/multi";
import {Summoner} from "./components/summoner";
import Home from "./components/home/Home";
import URF from "./components/urf";
import SummonerSearch from "./components/SummonerSearch";
import Pro from "./components/pro";
import Strm from "./components/page/Strm";
import Daily from "./components/daily";
import AramDaily from "./components/daily/AramDaily";
import DataLayer from "./components/DataLayer/DataLayer";
import CheckDataLayer from "./components/DataLayer/CheckDataLayer";
import {isbot} from "isbot";
import Test from "./components/test";
import Marquee from "./components/marquee";
import NewsList from "./components/marquee/newsList";


const AppWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

const LoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;


const Anchor = styled.div`
  position: fixed;
  bottom: 0;
  width: 1080px;
  left: 50%;
  transform: translate(-50%, 0);
`;
// 스토어생성
i18nInit();

const App = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const choArr = useMemo(() => ["ㄱ", "ㄲ", "ㄴ", "ㄷ", "ㄸ", "ㄹ", "ㅁ", "ㅂ", "ㅃ", "ㅅ", "ㅆ", "ㅇ", "ㅈ", "ㅉ", "ㅊ", "ㅋ", "ㅌ", "ㅍ", "ㅎ"], []);
    const themeColor = useSelector((state) => state.theme.color);
    if (document) {
        const canonical = document.querySelector('link[rel=canonical]');
        canonical.setAttribute("href", window.location.href);
    }


    useEffect(() => {
        if (document) {
            const canonical = document.querySelector('link[rel=canonical]');
            const langAlter = document.querySelectorAll('link[rel=alternate]');

            langAlter.forEach((data) => {

                const lang = data.getAttribute('hreflang')
                if (lang === 'x-default') {
                    data.setAttribute('href', `https://www.deeplol.gg${location.pathname}`)
                } else {
                    data.setAttribute('href', `https://www.deeplol.gg${location.pathname}?hl=${lang}`)
                }
                // data.setAttribute('href',)
            })

            canonical.setAttribute('href', `https://www.deeplol.gg${location.pathname}?hl=${i18n.language}`)
        }
    }, [location.pathname])

    const handleDescription = () => {
        const htmlTag = document.querySelector('html');
        const description = document.querySelector('meta[name=description]');
        const ogDescription = document.querySelector('meta[property="og:description"]');
        const snsDescription = document.querySelector('meta[property="twitter:description"]');
        const contentLanguage = document.querySelector('meta[http-equiv="content-language"]');
        const enDescription = 'DEEPLOL provides new strategies such as AI-score and Tier Prediction with AI technology. Check out AI-score, like Pro LoL teams.';
        const korDescription = '롤전적 검색 딥롤은 인공지능(AI) 기술로 AI Score, 티어 예측과 같은 새로운 게임 데이터를 제공합니다. 롤 프로팀도 사용하는 AI-score를 지금 확인해보세요!';
        const thisDes = i18n.language === 'ko-KR' ? korDescription : enDescription;

        contentLanguage.setAttribute('content', i18n.language);
        description.setAttribute('content', thisDes);
        ogDescription.setAttribute('content', thisDes);
        snsDescription.setAttribute('content', thisDes);
        htmlTag.setAttribute('lang', i18n.language);
    }

    const changeKeywords = (data) => {
        document.documentElement.lang = data[0] + data[1];
        const keywords = document.querySelector('meta[name=keywords]');
        keywords.setAttribute('content', t('header.keywords'));
    };

    const getGameVersion = useCallback(async () => {
        try {
            const res = await axios.get('https://ddragon.leagueoflegends.com/api/versions.json');
            dispatch(changeVersion(res.data[0], res.data));
            return res.data[0]
        } catch (e) {
            return "13.3.1";
        }
    }, []);

    const setChoName = useCallback((value) => {
        if (typeof value === 'string') {
            let result = "";
            for (let char of value) {
                if (char === "") {
                    continue;
                }
                if (char.charCodeAt() > 44031) {
                    const choValue = Math.trunc((char.charCodeAt() - 44032) / 588);
                    result += choArr[choValue];
                } else {
                    result += char;
                }

            }
            return result;
        }
    }, []);
    const setChampLang = (champion, resChampion) => {
        return resChampion.map((data) => {
            const findData = champion.find((champ) => champ.key === data.champion_id);
            return {
                ...data,
                champion_name: findData?.name,
            }
        })
    }


    const setChoNamedChampion = useCallback((data) => {
        let choNamedChampions
        if (Array.isArray(data)) {
            choNamedChampions = data.map((data, index) => {
                const choname = data.champion_name_kr ? setChoName(data.champion_name_kr) : data.champion_name_en;
                return {
                    ...data,
                    cho_name: choname
                }
            })
        }
        return choNamedChampions;
    }, []);

// 챔피언, 룬, 스펠, 아이템 스토어 등록 (이미지용)
    const getImageInfo = useCallback(async () => {
        try {

            const gameVersion = await getGameVersion();
            let _thisGameVersionArr = gameVersion.split('.');
            let _thisGameVersion = _thisGameVersionArr[0] + '.' + _thisGameVersionArr[1];
            dispatch({
                type: CLOUDFLARE_VERSION,
                data: _thisGameVersion,
            })


            const [resChampionLang, resChampions] = await Promise.all([
                axios.get(`${process.env.REACT_APP_CHAMP_LANG}?version=${_thisGameVersion}&lang=${getDataDragonRegion(i18n.language)}`),
                axios.get(`${process.env.REACT_APP_CACHE_API}common/champion-info?version=${_thisGameVersion}.1`),
                // axios.get(`${process.env.REACT_APP_RUNE}?version=${_thisGameVersion}&lang=${getDataDragonRegion(i18n.language)}`),
                // axios.get(`${process.env.REACT_APP_SPELL}?version=${_thisGameVersion}&lang=${getDataDragonRegion(i18n.language)}`),
                // axios.get(`${process.env.REACT_APP_ITEM}?version=${_thisGameVersion}&lang=${getDataDragonRegion(i18n.language)}`),
            ])


            // const [season, getAiData, oldSeason, getItemInfo, argments] = await Promise.all([
            //     axios.get(`${process.env.REACT_APP_CACHE_API}common/season-list`),
            //     axios.get(`${process.env.REACT_APP_CACHE_API}common/tier-boundary`),
            //     axios.get(`${process.env.REACT_APP_CACHE_API}common/old-season`),
            //     axios.get(`${process.env.REACT_APP_CACHE_API}common/item-info`),
            //     axios.get(`${process.env.REACT_APP_ROOT_IMG}latest/cdragon/arena/${getDataDragonRegion(i18n.language).toLowerCase()}.json`),
            //     axios.get(`${process.env.REACT_APP_NEW_API}common/country`).catch(() => 'KR'),
            //     axios.get(`https://raw.communitydragon.org/latest/plugins/rcp-be-lol-game-data/global/${i18n.language === 'KOR' ? 'ko_kr' : 'default'}/v1/challenges.json`).catch((e) => [])
            // ])
            const browserLang = navigator?.language ? navigator?.language : navigator?.userLanguage
            const country = browserLang.substring(0, 2).toUpperCase() === 'KO' ? 'KR' : 'ELSE'


            // if (Array.isArray(getStreamerTag?.data?.stream_pro_tag)) {
            //     arrayStreamerTag = getStreamerTag.data.stream_pro_tag.map((data) => {
            //         return {
            //             ...data,
            //             puu_id: data.b2c_key_puuid,
            //         }
            //     })
            // } else {
            //     arrayStreamerTag = [];
            // }

            const tooltipReady = true;

            const choNamedChampions = setChoNamedChampion(resChampions?.data?.champions);
            const setLangNames = setChampLang(Object.values(resChampionLang.data.data), choNamedChampions);

            dispatch(
                imageStore(
                    setLangNames,
                    tooltipReady,
                    country,
                )
            );
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, [i18n.language]);

    useEffect(() => {
        if (!isMobileMemo) {
            getImageInfo();
            handleDescription();
        }
    }, [getImageInfo]);


    useEffect(() => {
        dispatch(translation(i18n.language));
    }, [i18n.language])


    i18next.on("languageChanged", changeKeywords);

    const isMobileMemo = useMemo(() => {
        const multi = /multi|event/g
        const pathname = location.pathname;
        const from = new URLSearchParams(location.search);
        if (from.get('from') === 'mobile') {
            return false;
        }
        if (multi.test(pathname)) {
            return false;
        }
        return !isBrowser

    }, [location, isMobile]);

    //
    if (loading && !isMobileMemo) {
        return <LoadingWrapper><Loading/></LoadingWrapper>
    }
    //
    // return (
    //     <Maintenance/>
    // )
    const theme = {
        color: themeColor === 'light' ? light : dark,
        icon: themeColor === 'light' ? lightIcon : darkIcon
    }


    const IsOnlyMobile = () => {
        const from = new URLSearchParams(location.search);
        if (from.get('from') === 'mobile') {
            return false;
        }
        if (isbot(navigator.userAgent)) {
            return false;
        } else if (isMobile) {
            return true;
        }
        return false;
    }

    console.log("location", location.path)

    return (
        <DataLayer>
            <ThemeProvider theme={theme}>
                <GlobalStyles/>
                <BottomRightFloatingAd/>
                <AppWrapper>
                    <Router>
                        <Suspense fallback={null}>
                            <Marquee/>
                        </Suspense>
                        <Switch>
                            <Route path="/multi/:region/:name">
                                <Multi/>
                                <Footer
                                />
                            </Route>
                            <Route path="/multi">
                                <Multi/>
                                <Footer
                                />
                            </Route>
                            <Route path={'/event'}>
                                <Redirect to={'/story'}/>
                            </Route>
                            {/*<Route path="*" component={ToMobile}></Route>*/}
                            {IsOnlyMobile() &&
                                <Route path="*" component={ToMobile}></Route>}
                            <Route exact path={'/policies/privacy'}>
                                {/*<Privacy/>*/}
                                <Footer
                                />
                            </Route>
                            <Route exact path="/">
                                <Home/>
                                <Footer
                                />
                            </Route>
                            <Route exact path={'/pro/:proName'}>
                                <Pro/>
                                <Footer
                                />

                            </Route>
                            <Route exact path={'/strm/:proName'}>
                                <Strm/>
                                <Footer
                                />

                            </Route>
                            <Route exact path={'/spectate/live/pro'}>
                                <SpectateLivePro/>
                                <Footer
                                />

                            </Route>
                            <Route path="/summoner/search/:platform/:summonerName">
                                <SummonerSearch/>
                                <Footer
                                />

                            </Route>
                            <Route exact path="/summoner/:region/:userName/matches/:matchId">
                                <CheckDataLayer>
                                    <Summoner/>
                                    <Footer
                                    />

                                </CheckDataLayer>
                            </Route>
                            <Route path="/summoner/:region/:userName">
                                <CheckDataLayer>
                                    <Summoner/>
                                    <Footer
                                    />

                                </CheckDataLayer>
                            </Route>
                            <Route exact path="/summoner/:userName">
                                <CheckDataLayer>
                                    <Summoner/>
                                    <Footer
                                    />

                                </CheckDataLayer>
                            </Route>

                            <Route path="/champions/:champName">
                                <ChampionsDetail/>
                                <Footer
                                />

                            </Route>
                            <Route exact path="/champions">
                                <Champions/>
                                <Footer
                                />

                            </Route>
                            <Route exact path="/aram">
                                <Aram/>
                                <Footer
                                />

                            </Route>
                            <Route exact path="/urf">
                                <URF/>
                                <Footer
                                />

                            </Route>
                            <Route exact path="/mastery">
                                <Mastery/>
                                <Footer
                                />

                            </Route>
                            <Route path="/balance">
                                <Balance/>
                                <Footer
                                />

                            </Route>
                            <Route exact path="/ranking/:platform/:position">
                                <Ranking/>
                                <Footer
                                />
                            </Route>
                            <Route exact path="/daily/ai/:platform/:queue(aram)">
                                <AramDaily/>
                                <Footer
                                />

                            </Route>
                            <Route exact path="/daily/ai/:platform/:queue/:tier">
                                <Daily/>
                                <Footer
                                />
                            </Route>
                            <Route exact path={'/test'}>
                                <Test/>
                            </Route>
                            <Route exact path={'/story'}>
                                <NewsList/>
                            </Route>
                            <Route>
                                <NotFound/>
                                <Footer
                                />
                            </Route>
                        </Switch>
                    </Router>
                </AppWrapper>
            </ThemeProvider>
        </DataLayer>
    );
};

const FloatingAd = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 300px;
  z-index: 999999999;
`;

const VideoAd = styled.div`
  width: 100%;
  height: 100%;
  --ad-width: 300px;
`;

const BottomRightFloatingAd = memo(() => {


    return (
        <FloatingAd>
            <VideoAd data-ad={'video-top'}/>
        </FloatingAd>
    )
})

export default App;
