// 액션 생성
export const OPEN_CHART_MODAL = 'modal/CHART_MODAL';
export const CLOSE_CHART_MODAL = 'modal/MODAL_CLOSE'
// 액션 함수 내보내기

;

// 초기 상태 값
const initialState = {
    open: false,
    modalData: {},
    myMatch: {},
    match: [],
    info:{},
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_CHART_MODAL:
            return {
                open: action.open,
                modalData: action.modalData,
                info: action.info,
                match:action.match
            };

        case CLOSE_CHART_MODAL:
            return {
                ...initialState,
            }
        default:
            return state;
    }
};
export default reducer;
