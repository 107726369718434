import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import dompurify from 'dompurify';
import {cutLaneName} from '../../function';
import MultiSearch from './MultiSearch';
import MultiList from './MultiList';
import {MultiContainer} from '../../styled/MultiStyle';
import styled from "styled-components";
import {Header, Loading} from "../common";
import {GET_MULTI_LIST_REQUEST, GET_SHORT_LINK_REQUEST} from "../../store/reducer/summoner";
import _ from "lodash";
import MultiEmpty from "./Empty";
import ServerStatusErrorbox from "../common/Error/StatusError";
import {changeRegion} from "../../store/modules/region";
import regionList from "../../store/static_json/regionList";
import ScoreWrapper from "../summoner/DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";
import {multi_ad_content} from "../../ad/bidrich";
import useAdHooks from "../../common/hooks/useAdHooks";
import CheckDataLayer from "../DataLayer/CheckDataLayer";
import UseSetCanonical from "../../common/hooks/useSetCanonical";
import useTitleAndDescription from "../../common/hooks/useTitleAndDescription";
import RightRailAd from "../ad/RightRailAd";
import LeftRailAd from "../ad/LeftRailAd";


const ContentWrapper = styled.div`
  margin: 0 auto;
  @media only screen and (max-width: 1200px) {
    margin: 0;
  }
`;


const ImgWrapper = styled.div`
  display: flex;
`;

const PickImg = styled.img`
  width: fit-content;
  max-height: 289px;
`;


const AdBox = styled.div`
  flex: 1;
`;

const ItemWrapper = styled.div`
  width: 1080px;
  display: flex;
  flex-direction: column;
  margin: 15px;
`;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${props => props.theme.color.site_background_color};
  margin-bottom: 10vh;
  border-radius: 6px;
  z-index: 1;
`;

const ScoreBox = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.color.site_main_color};
  justify-content: space-between;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  padding: 15px;
  margin: 8px 0;
`;

const AIScoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AIScoreWrapperSpan = styled.span`
  margin-right: 18px;
  font-size: 18px;
  font-weight: bold;
`;

const VideoWrapper = styled.div`
  min-width: 410px;
`;


const SmallRail = styled.div`
  width: 300px;
  margin-bottom: 15px;
`;


const LeftSideWrapper = styled.div`
  width: 300px;
`;


const StickyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
`;


const RightSIdeStickyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
`;

const StickyBoxWrapper = styled.div`
  width: 340px;
  padding: 20px;
  position: relative;
`;
const Multi = () => {
        UseSetCanonical();
        const dispatch = useDispatch();
        const {t} = useTranslation();
        const sanitizer = dompurify.sanitize;
        const [inpText, setInpText] = useState('');
        const currentLang = useSelector((state) => state.translation.lang);
        const currentSeason = useSelector((state) => state.imageStore.currentSeason);
        useAdHooks(multi_ad_content);
        const [init, setInit] = useState(true);
        const {
            multiList,
            multiLPChart,
            getMultiListLoading,
            getMultiListUpdateLoading,
            getMultiListUpdateDone,
            getMultiListDone,
            shortLink,
            getShortLinkDone,
            getShortLinkLoading,
            setShortLink
        } = useSelector((state) => state.summoner);
        const [copy, setCopy] = useState({
            copyUrl: window.location.href,
            copied: false,
        });
        const [avg, setAvg] = useState(0);
        const region = useSelector((state) => state.region.result);
        const location = useLocation();
        const params = useParams();
        const history = useHistory();


        const laneText = useCallback((lane, currentLang) => {
            if (currentLang !== 'KOR') {
                return cutLaneName(lane).toUpperCase();
            }

            switch (lane) {
                case 'Top':
                    return '탑';
                case 'Jungle':
                    return '정글';
                case 'Middle':
                    return '미드';
                case 'Bot':
                    return '바텀';
                case 'Supporter':
                    return '서폿';
                default:
                    break;
            }

        }, [])


        const getMultiTitle = () => {
            const nameList = multiList.map((data) => {
                return data?.summoner?.summoner_basic_info_dict?.riot_id_name
            })
            if (multiList.length === 0) {
                return ''
            }
            if (multiList.length === 1) {
                return nameList[0]
            }
            if (multiList.length === 2) {
                return `${nameList[0]},${nameList[1]}`
            }
            return `${nameList[0]},${nameList[1]}....`
        }

        useTitleAndDescription({
            title: multiList.length > 0 ? t('header.titleMultiSearched', {
                summoners: getMultiTitle(),
            }) : t('header.titleMulti'),
            description: multiList.length > 0 ? t('header.titleMultiSearchedDescription', {
                summoners: getMultiTitle(),
            }) : t('header.titleMultiDescription')
        })

        useEffect(() => {

            if (getShortLinkDone && !getShortLinkLoading && currentSeason && currentSeason !== 17) {
                let dispatchText = '';
                if (shortLink.summoners) {
                    window.history.replaceState(null, null, `/multi/${shortLink.region}/${shortLink.summoners}`);
                    dispatchText = shortLink.summoners;
                    setInpText(decodeURIComponent(shortLink.summoners));
                } else {
                    const urlText = params.name;
                    dispatchText = params.name;
                    setInpText(decodeURIComponent(urlText));
                }


                const paramRegion = regionList.find((data) => (data.title.toUpperCase() === params?.region?.toUpperCase() && !data.disabled));
                dispatch(changeRegion(paramRegion ? paramRegion.title : shortLink.region));


                if (paramRegion) {
                    dispatch({
                        type: GET_MULTI_LIST_REQUEST,
                        data: {
                            summoners: dispatchText,
                            region: params.region ? params.region : shortLink.region,
                            currentSeason: currentSeason
                        }
                    })
                }

            }
        }, [getShortLinkDone, getShortLinkLoading, params, currentSeason]);

        useEffect(() => {
            if (getMultiListUpdateDone && !getMultiListUpdateLoading) {
                if (multiList.length > 0) {
                    const reduceAiScore = multiList.reduce((sum, cur) => {
                        sum += cur.summoner_info_dict.ai_score_avg;
                        return sum
                    }, 0);
                    setAvg(Math.trunc(reduceAiScore / multiList.length));
                }
            }
        }, [getMultiListUpdateLoading, getMultiListUpdateDone, multiList]);


        const getCount = (array, flag) => {
            try {
                const copyArray = [...array];
                const result = [];

                for (let i in copyArray) {
                    const findIndex = result.findIndex((data, index) => data[flag] === copyArray[i][flag]);
                    if (findIndex > -1) {
                        result[findIndex] = {
                            ...result[findIndex],
                            gameCount: result[findIndex].gameCount + 1,
                            ai_score: result[findIndex].ai_score + copyArray[i].ai_score,
                            wins: copyArray[i].win ? result[findIndex].wins + 1 : 0,
                        }
                    } else {
                        result.push({
                            gameCount: 1,
                            [flag]: copyArray[i][flag],
                            ai_score: copyArray[i].ai_score,
                            wins: copyArray[i].win ? 1 : 0,
                        });
                    }
                }

                result.sort((a, b) => {
                    if (a.gameCount < b.gameCount) {
                        return 1;
                    } else if (a.gameCount > b.gameCount) {
                        return -1;
                    } else if (a.gameCount === b.gameCount) {

                        const aScore = a.ai_score / a.gameCount;
                        const bScore = b.ai_score / b.gameCount;

                        if (a.wins < b.wins) {
                            return 1;
                        } else if (a.wins < b.wins) {
                            return -1;
                        } else if (a.wins === b.wins) {
                            if (aScore < bScore) {
                                return 1;
                            } else if (aScore > bScore) {
                                return -1;
                            } else {
                                return 0;
                            }
                        }
                    }
                });
                return result;
            } catch (e) {
                return array
            }
        }

        const carryFunction = (list) => {

            if (list?.losses !== undefined && list?.wins !== undefined) {
                if (list.losses + list.wins > 4) {
                    if (list.ai_score_avg > 56) {
                        return true;
                    }
                }
            }

            return false;
        }


// ai score 평균


// 공유하기
        const handleCopy = () => {
            const array = _.uniqBy(multiList, (e) => e.summoner.summoner_basic_info_dict.summoner_id);
            const sortedMultilist = array.sort((a, b) => b.summoner_info_dict.ai_score_avg - a.summoner_info_dict.ai_score_avg);


            const resultArray = [];
            if (sortedMultilist.length > 0) {
                resultArray.push(getCount(sortedMultilist[0].summoner_info_dict.last_match_list, 'position')[0]);
            }

            if (sortedMultilist.length > 1) {
                resultArray.push(getCount(sortedMultilist[1].summoner_info_dict.last_match_list, 'position')[0]);
            }


            const jum = currentLang === 'KOR' ? '점' : "";


            let copyText = `DEEPLOL.GG ${t('multi.copyHeader')}\n\n`
            copyText += `- ${t('multi.copyAI')} AI-Score : ${avg}${jum}\n\n`


            if (carryFunction(sortedMultilist[0]?.summoner_info_dict)) {
                copyText += `- ${t('tags.carry')} 1 : ${sortedMultilist[0].summoner.summoner_basic_info_dict.riot_id_name}(${laneText(resultArray[0].position, currentLang)},${sortedMultilist[0].summoner_info_dict.ai_score_avg}${jum})\n\n`
                if (carryFunction(sortedMultilist[1]?.summoner_info_dict)) {
                    copyText += `- ${t('tags.carry')} 2 : ${sortedMultilist[1].summoner.summoner_basic_info_dict.riot_id_name}(${laneText(resultArray[1].position, currentLang)},${sortedMultilist[1].summoner_info_dict.ai_score_avg}${jum})\n\n`
                }
            }

            copyText += `- ${window.location.origin + '/multi/'}${region}/${setShortLink.urlkey}`
            navigator.clipboard.writeText(copyText);

            setCopy({copied: true});
            closeCopied();
        };

// 공유하기 툴팁
        const closeCopied = () => {
            setTimeout(() => {
                setCopy({copied: false});
            }, 2000);
        };


// 소환사명 받아서 하나씩 배열로 변경
        const getSummonersName = (text) => {
            if (typeof text === 'string') {
                // (joined the room.)| (님이|로비에|참가하셨습니다.)|(,\s)|,|\n|\r
                let arr_str = text.replace(/(joined the room.)|(joined the lobby.)|(joined the lobby)|(님이 로비에 참가했습니다.)|(님이 로비에 참가하셨습니다.)|(がロビーに参加しました)|(,\s)|,|\n|\r/gi, '/');
                arr_str = arr_str.replace(/(\s\/)+|(\s\/)/gi, '/');
                arr_str = arr_str.replace(/(\/\s)|(\/)+/gi, '/');
                arr_str = arr_str.replace(/\/$/gi, '');
                arr_str = arr_str.split('/');


                // 중복, 빈값 제거
                const set = new Set(arr_str);
                const uniqueArr = [...set];
                const modyArr = uniqueArr.filter((item) => item !== '').slice(0, 8);


                history.replace(`/multi/${region}/${encodeURIComponent(modyArr)}`);
                setInit(false);
                // dispatch({
                //     type: GET_MULTI_LIST_REQUEST,
                //     data: {
                //         summoners: modyArr.toString(),
                //         region: region,
                //         currentSeason: currentSeason
                //     }
                // })
                // history.replace({
                //     pathname: `/multi/${modyArr}`,
                //     state: {name: modyArr},
                // });
            }
        };


        useEffect(() => {
            setAvg(0);
            if (typeof params.name === 'string') {
                let text = params.name;
                if (text.length > 0) {
                    dispatch({
                        type: GET_SHORT_LINK_REQUEST,
                        data: {
                            short: text
                        },
                    });
                    setInit(false);
                }
            }
        }, [params]);

        const onClickIngameButton = () => {
            if (multiList.length > 0) {
                let summonerName = multiList[0]?.summoner?.summoner_basic_info_dict?.riot_id_name;
                const tag = multiList[0]?.summoner?.summoner_basic_info_dict?.riot_id_tag_line
                if (summonerName) {
                    const origin = window.location.origin;
                    window.open(`${origin}/summoner/${region}/${summonerName}-${tag}/ingame`, '_blank',);
                }

            }
        }


        return (
            <div>

                <Header/>
                <ServerStatusErrorbox/>
                <CheckDataLayer>
                    <MultiContainer id={'multi_content'}>
                        <LeftRailAd/>
                        <ItemWrapper>
                            <div id={'multi_content_top_ad_box'}>

                            </div>

                            <CenterDiv id={'multi_content_wrapper'}>
                                <div className="multi_top">
                                    <ContentWrapper className="center" id="trendi-player-container">
                                        <MultiSearch getSummonersName={getSummonersName} inpText={inpText}/>
                                        <VideoWrapper className={'ad-video-box'} id="trendi-player-placeholder">

                                        </VideoWrapper>
                                    </ContentWrapper>
                                </div>
                                <ScoreBox>
                                    <AIScoreWrapper>
                                        <AIScoreWrapperSpan>{t('multi.teamAvg')} AI-Score</AIScoreWrapperSpan>
                                        <ScoreWrapper
                                            fontSize={26}
                                            paddingHorizontal={14}
                                            score={isNaN(avg) ? 0 : avg}
                                            noneClickable={true}
                                        />
                                    </AIScoreWrapper>

                                    <div className="buttons">
                                        {/*<button className="ingame" onClick={onClickIngameButton}>*/}
                                        {/*    {t('buttons.ingame')}*/}
                                        {/*</button>*/}
                                        {/*<div onClick={handleCopy} text={copy.copyUrl}>*/}
                                        {/*    <button className="share">{t('buttons.share')}</button>*/}
                                        {/*</div>*/}
                                        {/*{copy.copied && <span className="share_tooltip">{t('multi.clipboard')}</span>}*/}
                                    </div>
                                </ScoreBox>
                                <div className="multi_bottom">
                                    <ContentWrapper className="contentCenter">
                                        {getMultiListLoading ? <Loading/> :
                                            (init || !getMultiListDone) ? (
                                                <div className="how_to_use">
                                                    <h3>{t('multi.howtouse')}</h3>
                                                    <div className="box">
                                                        <p dangerouslySetInnerHTML={{__html: sanitizer(t('multi.text'))}}></p>
                                                        <div className="imgs">
                                                            <ImgWrapper>
                                                                <PickImg src="/images/img_picks.png" alt="picks"
                                                                         className="picks"/>
                                                            </ImgWrapper>

                                                            <img src="/images/icon_s_expand_arrow.svg" alt="arrow"/>

                                                            <ImgWrapper>
                                                                <PickImg src="/images/img_browser.png" alt="browser"
                                                                         width={'100%'}
                                                                         className="browser"/>
                                                            </ImgWrapper>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                multiList.length > 0 ?
                                                    <MultiList
                                                        isLoading={getMultiListUpdateLoading}
                                                        searchNames={setShortLink}
                                                        multiLPChart={multiLPChart}
                                                        multiList={multiList}/>
                                                    :
                                                    <MultiEmpty/>
                                            )
                                        }

                                    </ContentWrapper>

                                </div>

                                {/*{multiList.length > 0 && <MultiChart/>}*/}
                            </CenterDiv>
                            <div id={'multi_content_bottom_ad_box'}>

                            </div>
                        </ItemWrapper>

                        <RightRailAd/>
                    </MultiContainer>
                </CheckDataLayer>

            </div>
        );
    }
;




export default Multi;
