import React, {useMemo} from 'react';
import {useQueries, useQueryClient} from "@tanstack/react-query";
import {summonerSearchAPI} from "../../api/summonerSearchAPI";
import {useParams} from "react-router-dom";
import regionList from "../../store/static_json/regionList";
import {getProAndStreamerSeachAPI} from "../../store/saga/summoner";

const useSummonerSearchApi = () => {
    const {platform, summonerName} = useParams();
    const rankingRegion = useMemo(() => regionList.find((data) => data.title.toLowerCase() === platform?.toLowerCase()), [platform]);
    const queryClient = useQueryClient();
    const query = useQueries(
        {
            queries: [
                {
                    queryKey: ["summonerSearchAPI", platform, summonerName],
                    queryFn: async () => {
                        return await summonerSearchAPI({
                            platform_id: platform,
                            riot_id_name: summonerName
                        })
                    },
                    retry: 0,
                    refetchOnWindowFocus: false,
                    initialData: {
                        lists: [],
                    },
                    select: (data) => {
                        try {
                            if (data.lists.length > 0) {
                                let list = [...data.lists];
                                // const filterRiotId = list.filter((data) => data.riot_id_tag_line !== rankingRegion.tagName);
                                // const defaultSummoner = list.filter((data) => data.riot_id_tag_line === rankingRegion.tagName);
                                // const addTierValue = filterRiotId.map((data) => {
                                //     const splitTier = data.tier.split(" ");
                                //     const splitDivision = splitTier[1]
                                //     return {
                                //         ...data,
                                //         tierValue: getTierValue(splitTier[0]) + getDivisionValue(romanNumberConverter(splitDivision))
                                //     }
                                // });
                                // const sortedList = sortChampData(addTierValue, [{
                                //     sortType: false,
                                //     value: 'tierValue',
                                // }, {
                                //     sortType: false,
                                //     value: 'lp',
                                // }, {
                                //     sortType: false,
                                //     value: 'summoner_level'
                                // }])
                                return list;
                            }
                            return [];
                        } catch (e) {
                            return []
                        }
                    }
                },
                {
                    enabled: !queryClient.getQueryData(['proStreamerSearch', summonerName]),
                    queryKey: ['proStreamerSearch', summonerName],
                    queryFn: async () => {
                        const result = await getProAndStreamerSeachAPI({
                            username: summonerName
                        })
                        return result
                    },
                    retry: 0,
                    refetchOnWindowFocus: false,
                    select: (data) => {
                        if (Array.isArray(data?.pro)) {
                            return [...data.pro, ...data.streamer]
                        }
                    },
                },
            ]
        });


    return {
        summonerSearchQuery: query[0],
        proStreamer: query[1],
    }
};

export default useSummonerSearchApi;