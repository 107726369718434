import styled from 'styled-components';
import {AI_SCORE_BAD_TEXT} from "./scoreColor";

export const SummonerContainer = styled.div`
  z-index: 1;
  position: relative;
  min-height: 30vh;
  padding-bottom: 100px;
  min-width: 1110px;

  .not_found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 120px;

    h5 {
      margin: 30px 0 20px;
      font-size: 42px;

      span {
        font-weight: 700;
      }
    }

    p {
      font-size: 20px;
    }
  }

  .no_game {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;

    p {
      font-size: 36px;
    }
  }

  .main_tab {
    width: 1080px;
    margin: 0 auto;
  }

  .main_tab_list {
    position: relative;
    display: flex;
    align-items: center;
    padding: 7px 8px;
    background-color: ${props => props.theme.color.site_main_color};
    border: 1px solid ${props => props.theme.color.default_border_color};
    border-radius: 6px;

    .react-tabs__tab {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 108px;
      padding: 0 26px;
      height: 32px;
      border-radius: 8px;
      font-size: 18px;
      font-weight: 400;
      color: ${props => props.theme.color.dark_200};
      cursor: pointer;
      opacity: 1;

      &:hover {
        color: ${props => props.theme.color.dark_200};
      }

      &--selected {
        font-weight: 400;
        color: ${props => props.theme.color.white_300};
        opacity: 1;

        &:hover {
          color: ${props => props.theme.color.site_main_color};
        }
      }

      & + .react-tabs__tab {
        margin-left: 20px;
      }
    }

    .live {
      display: flex;
      position: relative;
      width: 28px;
      height: 14px;
      padding-left: 4px;

      img {
        position: absolute;
        top: -1px;
      }
    }
  }

  .main_tab_panel {
    position: relative;
  }
`;
export const ProfileContainer = styled.div`
  width: 1080px;
  margin: 0 auto;
  padding: 10px 0 0;

  .p__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90;

    .img {
      display: block;
      width: 100%;
      height: 100%;
      background-attachment: fixed;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: 100%;
      opacity: 0.95;
    }

    &::after {
      content: '';
      position: absolute;
      top: 430px;
      left: 0;
      display: block;
      width: 100%;
      height: calc(100% - 430px);
      background: ${props => props.theme.color.site_main_color};
      backdrop-filter: blur(20px);
    }
  }

  .p__info {
    position: relative;
    z-index: 100;
    display: flex;
    flex: 1;
    border-radius: 6px;
    background-color: ${props => props.theme.color.site_main_color};
    border: 1px solid ${props => props.theme.color.default_border_color};

    .icon {
      position: relative;

      img {
        border-radius: 6px;
      }

      p {
        position: absolute;
        top: 85px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        height: 26px;
        padding: 0 6px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        font-size: 15px;
        font-weight: 400;
        background: transparent linear-gradient(180deg, #2F4D3C 0%, #233048 100%);
        color: #fff;
      }
    }

    .text {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 13px;

      .pro {
        display: block;
        font-size: 12px;
        font-weight: 400;
        color: ${props => props.theme.color.site_main_color};
      }

      .name {
        display: flex;
        align-items: center;

        p {
          font-size: 22px;
          font-weight: bold;
          color: ${props => props.theme.color.dark_100};
        }
      }

      .update {
        display: flex;
        align-items: flex-end;

        button {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          width: 120px;
          height: 32px;

          font-size: 12px;


          &.complete {
            background: transparent;

            &::before {
              display: none;
            }
          }

          .fill {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
          }

          span {
            position: relative;
          }
        }

        p {
          margin-left: 10px;
          margin-bottom: 1px;
          font-size: 11px;
          font-weight: 400;
        }
      }

      .favorite {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 12px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          width: 32px;
          border-radius: 6px;
          padding: 0 2px;
          font-size: 12px;
          transition: all 0.2s linear;
          border: 1px solid ${props => props.theme.color.dark_300};

          &::before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
          }

          &.off {

            &:hover {
              opacity: 1;
            }

            &::before {
              background: url('/images/ico_star_off.svg') no-repeat center;
              background-size: 24px 24px;
            }
          }

          &.on {
            opacity: 1;

            &::before {
              background: url('/images/ico_star_on.svg') no-repeat center;
              background-size: 24px 24px;
            }
          }
        }
      }
    }
  }

  .p__tier {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .item {
      display: inline-flex;
      align-content: center;
      justify-content: center;
      height: 18px;
      line-height: 18px;
      padding: 0 7px;
      background: rgba(13, 19, 36, 0.7);
      border-radius: 4px;
      font-size: 12px;
      color: #e5e5e5;

      i {
        display: inline-block;
        margin-right: 4px;
      }

      span {
        font-weight: 500;
        text-transform: capitalize;
      }
    }

    .item + .item {
      margin-left: 4px;
    }
  }
`;

export const InfoContainer = styled.div`
  padding: 9px 0 13px;

  .p__box {
    display: flex;
    justify-content: space-between;
    height: 254px;

    .tier {
      display: flex;
      width: 570px;
      padding: 10px;
      background-color: ${props => props.theme.color.site_main_color};
      border: 1px solid ${props => props.theme.color.champion.default_border_color};
      border-radius: 6px;

      .react-tabs__tab-list {
        width: 235px;
        margin-right: 14px;
        border-radius: 6px;
      }

      .react-tabs__tab {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 110px;
        background: #ffffff;

        & + .react-tabs__tab {
          margin-top: 10px;
        }

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          opacity: 1;
          position: absolute;
          top: 0%;
          left: 0;
          outline: 1px solid ${props => props.theme.color.champion.default_border_color};
          box-sizing: border-box;
          border-radius: 6px;
          transition: all 0.2s linear;
        }

        &--selected {
          &::before {
            opacity: 1;
          }
        }

        .title {
          display: flex;
          align-items: center;
          height: 25px;
          padding: 0 12px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          h5 {
            text-align: center;
            width: 62px;
            font-size: 12px;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          dl {
            margin-left: 8px;
            display: flex;


            dt {
              margin-left: 3px;
              font-size: 11px;
              color: ${props => props.theme.color.dark_100};

              span {
                display: inline-block;
                color: ${props => props.theme.color.dark_100};
                font-size: 11px;
              }
            }

            dd {
              margin-left: 5px;
              font-size: 11px;
              color: ${props => props.theme.color.dark_100};
            }
          }
        }

        .info {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 0 12px;

          .t_group {
            flex: 1;
            margin: 0 8px 0 12px;
            font-size: 12px;


            .t1 {
              font-size: 16px;
              font-weight: 700;
              color: ${props => props.theme.color.dark_100};
            }

            .t2 {
              margin-top: 4px;
              color: ${props => props.theme.color.dark_100};
              opacity: 0.8;
              font-size: 12px;

              .lp + .game {
                margin-left: 10px;
              }

              .game {

              }

              .game i {
                color: ${props => props.theme.color.dark_100};
              }
            }

            .t3 {
              opacity: 0.8;
              margin-top: 2px;

              .game i {
                color: ${props => props.theme.color.dark_100};
              }

              .game + .game {
                margin-left: 10px;
              }
            }

            span {
              display: inline-block;
            }
          }
        }
      }

      .react-tabs__tab-panel {
        display: none;
        flex: 1;

        &--selected {
          display: block;
        }

        .title {
          margin: 5px 0 12px 22px;
          font-size: 14px;
          font-weight: 700;

          span {
            font-size: 12px;
            font-weight: 400;
          }
        }

        .tooltip {
          p {
            font-size: 11px;
            font-weight: 400;

            span {
              font-weight: 700;
            }
          }
        }
      }
    }

    .champ {
      display: flex;
      flex-direction: row-reverse;
      width: 550px;
      background-color: ${props => props.theme.color.site_main_color};
      border: 1px solid ${props => props.theme.color.champion.default_border_color};
      border-radius: 0;

      &.loading {
        align-items: center;
        justify-content: center;
      }

      .react-tabs__tab-list {
        overflow: hidden;
        border-left: 1px solid #aaadc2;
      }

      .react-tabs__tab {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 58px;
        height: 58px;
        background-color: #BDD5D5;
        font-size: 12px;
        font-weight: 400;
        color: ${props => props.theme.color.site_main_color};
        line-height: 1.4;
        cursor: pointer;
        transition: all 0.2s linear;

        & + .react-tabs__tab {
        }

        &--selected {
          font-weight: bold;
          background-color: #3FB3B4;
        }
      }

      .react-tabs__tab-panel {
        border-bottom-left-radius: 6px;
        display: none;
        flex: 1;

        &--selected {
          display: block;
        }

        table {
          width: 100%;
          text-align: center;
          border-bottom-left-radius: 6px;

          th {
            height: 42px;
            vertical-align: middle;
            font-size: 12px;
            font-weight: normal;
            opacity: 0.8;

            p {
              display: inline-flex;
              align-items: center;
              justify-content: space-around;
              height: 17px;
              padding: 0 10px;
              background-color: #bfcadb;
              border-radius: 4px;

              span {
                font-size: 11px;
                font-weight: 400;
              }
            }
          }


          td {
            height: 42px;
            vertical-align: middle;
            font-size: 14px;
            font-weight: 400;


            i {
              display: inline-block;
              margin: 0 2px;
              font-size: 12px;
            }

            p {
              margin-top: 4px;
              font-size: 12px;

              .death {
              }
            }

            &.no_data {
              border-bottom: 0;
              border-bottom-left-radius: 6px;
              height: 208px;
            }
          }


          tbody tr:nth-of-type(odd) {
            background-color: ${props => props.theme.color.site_main_color};
          }

          tbody tr:nth-of-type(even) {
            background-color: ${props => props.theme.color.background_F1};
          }

          tbody tr:first-of-type td {
            border-top: 1px solid #7f8298;
          }

          tbody tr:nth-child(5) {
            td {
              border-bottom: 0;
            }

            td:first-child {
              border-bottom-left-radius: 6px;
              border-bottom: 0;
            }

            border-bottom-left-radius: 6px;
          }
        }

        .imgChamp {
          width: 38px;
        }

        .imgDiv {
          border-radius: 6px;
        }
      }
    }
  }

  .imgEmblem {
    width: 62px;
  }

  .chart_lp {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    font-size: 18px;
  }
`;

export const OverviewContainer = styled.div`
  background: #f4f4f5;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 10px;
  backdrop-filter: blur(6px);

  .o__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 47px;
    padding: 0 20px;
    border-bottom: 1px solid ${props => props.theme.color.default_border_color};

    nav {
      display: flex;

      button {
        position: relative;
        padding: 8px 10px;
        margin-right: 15px;
        font-size: 14px;
        font-weight: 400;
        color: #828282;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 3px;
          overflow: hidden;
          background: transparent linear-gradient(93deg, #1eb8a0 0%, #3c68b2 100%);
        }

        &:hover {
          color: #3679AE;
          font-weight: bold;
        }

        &.selected {
          color: ${props => props.theme.color.font_21};
          font-weight: bold;

          &::after {
            width: 100%;
            transition: all 0.2s linear;
          }
        }
      }
    }
  }

  .o__info {
    display: flex;

    .chart_box {
      flex: 1;
      padding: 0 5px;

      h5 {
        margin: 13px 12px 0;
        font-weight: 700;
      }
    }

    .chart_dot {
      position: absolute;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 12px;
      margin-left: -8px;
      background-color: ${props => props.theme.color.site_main_color};
      border-radius: 2px;
      opacity: 0.9;
      font-size: 11px;
      font-weight: 400;

      &.good {
        bottom: -22px;
        color: #259a7e;

        .crown {
          top: -36px;
        }
      }

      &.bad {
        top: -22px;
        color: ${props => props.theme.color.red_300};

        .crown {
          bottom: -36px;
        }
      }

      .crown {
        position: absolute;
        left: 50%;
        margin-left: -11px;
      }
    }


    .chart_ai_label {
      display: flex;
      flex-direction: column;
      text-align: center;
      border-top: 1px solid ${props => props.theme.color.site_main_color};
      font-size: 11px;

      .date {
        display: flex;
        justify-content: center;
        align-items: center;
        border-style: solid;
        border-color: white;
        border-top: 1px solid ${props => props.theme.color.site_main_color};
        background-color: #b1b3c9;
      }

      .rating {
        display: flex;
        flex: 1 1 100%;
        width: 100%;
        border-style: solid;
        border-color: white;
        border-top: 1px solid ${props => props.theme.color.site_main_color};
        justify-content: center;
        align-items: center;
        background-color: #d3d4e1;
      }

      .win {
        flex: 1 1 100%;
        border-style: solid;
        border-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #b4d1e9;
      }

      .lose {
        border-style: solid;
        border-color: white;
        flex: 1 1 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e4baba;
      }
    }

    .highcharts-axis-labels {
      width: 100%;
      max-width: 477.8px;
      height: 50px;
      top: auto !important;
      left: auto !important;
      right: 9px;
      bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${props => props.theme.color.site_main_color};
    }

    .highcharts-axis-labels > span {
      position: relative !important;
      top: auto !important;
      left: auto !important;
      transform: rotate(0) !important;
      transform-origin: 0 !important;
      display: block !important;
      width: 100% !important;
      height: 50px;
    }

    .highcharts-axis-labels > span:last-of-type {
      border-right: 0;
    }
  }

  .no_data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 207px;
    text-align: center;
  }

  .champ {
    position: relative;
    width: 200px;

    &__search {
      position: relative;
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${props => props.theme.color.font_21};

      input {
        width: 100%;
        height: 28px;
        padding: 0 25px 0 10px;
        background: none;
        border: 0;

        &::placeholder {
          color: rgba(33, 33, 33, 0.5);
        }
      }

      button {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        background: url('/images/ico_search_w.svg') no-repeat center;
        background-size: cover;
        font-size: 0;
        text-indent: -9999px;
      }
    }

    &__autocomplete {
      position: absolute;
      top: 100%;
      left: 0;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      max-height: 140px;
      margin-top: 10px;
      padding: 10px 0;
      background: ${props => props.theme.color.site_main_color};
      box-shadow: 0px 3px 6px rgb(0 0 0 / 15%);
      border: 1px solid ${props => props.theme.color.dark_100};
      z-index: 99;

      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #b8cddc;
      }

      ::-webkit-scrollbar-track {
        background-color: #dbe8ff;
      }

      &-item {
        display: flex;
        align-items: center;
        height: 24px;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 400;
        color: #ababab;
        cursor: pointer;

        &:hover,
        &.selected {
          color: #3362e5;
          background: #dbe8ff;
        }
      }
    }
  }
`;

export const SummaryContainer = styled.div`
  position: relative;
  width: 570px;

  .row {
    display: flex;

    & + .row {
      border-top: 1px solid ${props => props.theme.color.default_border_color};
    }

    .col {
      padding: 13px 0;
      text-align: center;
      border-right: 1px solid ${props => props.theme.color.default_border_color};

      &.c1 {
        width: 140px;
      }

      &.c2 {
        width: 140px;
      }

      &.c3 {
        width: 105px;
      }

      &.c4 {
        width: 185px;
      }

      &.c5 {
        padding: 22px 0;
        align-items: center;
        justify-content: center;
        width: 280px;
      }

      &.c6 {
        padding: 22px 0;
        align-items: center;
        justify-content: center;
        width: 290px;
      }
    }

    h5 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;

      .info {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 7px;
        font-size: 0;
        text-indent: -9999px;
        background: url('/images/list_btn_tip_s_w.svg') no-repeat center;
        cursor: pointer;
      }
    }

    .mi {
      margin: 10px 0;
      font-size: 22px;
      font-weight: 700;

      .small {
        font-size: 12px;
        font-weight: 400;
        color: ${props => props.theme.color.font_rgba49};
      }
    }

    .sb {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin: 0 2px;
        font-size: 12px;

        &.death {
          color: ${props => props.theme.color.blue_400};
        }
      }

      .small {
        font-size: 11px;
        font-weight: 400;
        color: ${props => props.theme.color.font_rgba49};
      }
    }

    .predict {
      display: flex;
      justify-content: center;
      margin-top: 12px;


      strong {
        display: block;
        margin-top: 5px;
        font-size: 12px;
      }

      .arrow {
        display: inline-block;
        width: 10px;
        height: 8px;
        margin: 8px 5px;
        background: url('/images/ico_cell_right_arrow_w.svg') no-repeat center;
      }
    }

    .aiscore {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 94px;
      }

      p {
        display: flex;
        flex-direction: column;
        margin: 0 4px;
        font-size: 12px;

        span {
          display: block;
          margin-top: 5px;
          font-size: 22px;
          font-weight: 700;
        }
      }

      .rating {
        width: 100%;
        margin: 5px 0 0 0;
      }

      strong {
        display: block;
        margin-left: 3px;

        span {
          font-size: 47px;
          font-weight: 700;
        }
      }
    }

    .most {
      margin: 0 15px;

      dt {
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          margin-left: 15px;
          font-weight: 700;
        }
      }

      dd {
        margin-top: 8px;
        font-size: 12px;
        color: ${props => props.theme.color.font_rgba49};

        p {
          display: flex;
          align-items: center;
          line-height: 1.4;
        }

        span {
          display: inline-block;
          margin-right: 2px;
          color: ${props => props.theme.color.dark_100};
        }
      }
    }
  }

  .imgChamp {
    width: 40px;
  }

  .imgDiv {
    border-radius: 7px;
  }

  .imgLane {
    width: 40px;
  }
`;

export const MatchListContainer = styled.div`
  width: 100%;
  padding-bottom: 10px;

  .imgChamp {
    width: 58px;
  }


  .imgSpell {
    width: 24px;
    border-radius: 4px;
  }

  .imgRune {
    width: 24px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.8);

    & + .imgRune {
      padding: 2px;
    }
  }

  .imgLane {
    width: 20px;
  }

  .imgItem {
    width: 24px;
    border-radius: 4px;
  }

  .build_detail {
    cursor: pointer;
  }

  .more_game {
    position: relative;
    display: block;
    width: 100%;
    height: 40px;
    margin-top: 6px;
    background: ${props => props.theme.color.site_main_color};
    font-size: 16px;
    color: ${props => props.theme.color.dark_200};
    text-align: center;
    border: 1px solid ${props => props.theme.color.default_border_color};
    border-radius: 6px;

    &:hover {
      background-color: ${props => props.theme.color.white_201};
    }

    span {
      position: relative;
      z-index: 10;
    }

    .fill {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      background: rgba(245, 97, 97, 0.4);
    }

    &:disabled {
      cursor: auto;
      background-color: #b2b7bf;
    }
  }
`;

export const MatchItemContainer = styled.div`
  width: 100%;
  position: relative;


  .rowContainer {
    border: 1px solid ${props => props.theme.color.default_border_color};
    overflow: hidden;


    &.hardcarry {
      background-image: ${props => props.theme.color.gradient_hard};
      background-color: transparent;
    }

    &.win {
      background-color: ${props => props.theme.color.blue_300};

      &:hover {
        background-color: ${props => props.theme.color.blue_200};
      }


      .flex_column1 dt span {
        color: ${props => props.theme.color.blue_100};
      }


      &.close {
        border-radius: 5px;
      }

      &.open {
        border-radius: 5px 5px 0 0;
      }

    }

    &.lose {
      background-color: ${props => props.theme.color.red_400};

      &:hover {
        background-color: ${props => props.theme.color.red_200};
      }


      .flex_column1 dt span {
        color: ${props => props.theme.color.red_100};
      }

      &.close {
        border-radius: 5px;
      }

      &.open {
        border-radius: 5px 5px 0 0;

      }

    }

    &.hardcarry {
      .flex_column1 dt span {
        color: ${props => props.theme.color.font_5D_7A};
      }
    }

    &.remake {
      background-color: #b2b7bf;

      .flex_column1 dt span {
        color: #272727;
      }

      .flex_column7 button {
        display: none;
      }
    }

    &.open {
      .flex_column7 img {
        transform: rotate(180deg);
      }
    }
  }

  .row {
    position: relative;
    display: flex;
    align-items: center;
    height: 100px;
    cursor: pointer;
  }

  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
    font-size: 12px;
  }

  .flex_column0 {
    z-index: 2;
    width: 7px;
  }

  .flex_column1 {
    z-index: 2;
    align-items: flex-start;
    width: 120px;

    h5 {
      font-weight: 500 !important;
      font-size: 16px;
      line-height: 19px;

      &.win {
        color: ${props => props.theme.color.blue_100};
      }

      &.lose {
        color: ${props => props.theme.color.red_100};
      }

      &.hardcarry {
        color: ${props => props.theme.color.blue_100};
      }

    }

    p {
      font-size: 12px;
      margin-top: 4px;
      line-height: 14px;
      color: ${props => props.theme.color.dark_200};
    }

    dt {
      margin-top: 13px;

      span {
        display: inline-block;
        margin-right: 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
      }
    }

    dd {
      display: flex;
      align-items: center;
      margin-top: 5px;
      color: ${props => props.theme.color.white_300};

      > div {
        margin-left: 4px;
      }

      .tier_avg {
        display: inline-block;
        font-weight: 700;
        color: ${props => props.theme.color.white_300};

        &.t {
          text-transform: lowercase;

          &::first-letter {
            text-transform: capitalize;
          }
        }

        &.d {
          margin-left: 2px;
        }
      }
    }
  }

  .flex_column2 {
    z-index: 2;
    width: 330px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 10px 0 0 10px;

    .imgDiv {
      border-radius: 4px;
    }

    dl {
      min-width: 108px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    dt {
      position: relative;

      &.away {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4) url(/images/icon_exit_cross.svg) no-repeat center center;
          z-index: 5;
        }
      }

      p {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        margin: 6px 0 0;
        padding: 0 12px;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 4px;

        span {
          display: inline-block;
          margin-left: 2px;
          font-size: 11px;
        }
      }
    }

    dd {
      width: 24px;
      margin-left: 4px;

      div + div {
        margin-top: 4px;

        .imgRune {
          padding: 2px;
        }
      }
    }
  }

  .flex_column3 {
    width: 246px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .cbox {
      margin-right: 5px;
    }


    h5 {
      font-size: 12px;
      margin-bottom: 6px;
      opacity: 0.8;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${props => props.theme.color.dark_100};
      opacity: 0.8;
      min-height: 16px;
      margin-top: 5px;

      i {
        color: ${props => props.theme.color.dark_100};
        opacity: 0.8;
      }

      .crown {
        margin-right: 6px;
      }
    }
  }

  .flex_column4 {

    justify-content: center;
    width: 118px;

    p {
      display: flex;
      align-items: center;

      &.type1 {
        color: ${props => props.theme.color.font_rgba49};

        span {
          display: inline-block;
          margin-left: 6px;
          font-weight: 700;
        }
      }

      &.type2 {
        font-size: 10px;
        color: rgba(49, 49, 49, 0.4);

        span {
          display: inline-block;
          margin: 0 4px;
          font-size: 15px;
          font-weight: 700;
          color: ${props => props.theme.color.dark_100};

          &.death {
            color: ${props => props.theme.color.red_300};
          }
        }
      }

      &.type3 {
        color: ${props => props.theme.color.font_4908};

        span {
          display: inline-block;

          &.perfect {
            margin-right: 0;
          }
        }
      }

      &.type4 {
        margin: 6px 0 5px;

        span {
          display: inline-block;
          margin-right: 5px;
          color: ${props => props.theme.color.dark_100};
        }

        i {
          color: ${props => props.theme.color.dark_100};
        }
      }

      &.type5 {
        color: ${props => props.theme.color.font_rgba49};

        span {
          display: inline-block;
          margin-right: 2px;
          color: ${props => props.theme.color.dark_100};
        }
      }
    }
  }

  .flex_column5 {
    justify-content: space-between;
    width: 135px;

    .cbox {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;


      img:nth-child(n+1) {
        margin-left: 3px;
        margin-bottom: 2px;
      }

      img {
        //margin: 2px 1px;
        &:last-of-type {
          border: 0;
        }
      }
    }

    .vision {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 3px;

        img {
          margin-right: 2px;
        }
      }
    }
  }

  .flex_column6 {
    width: 280px;
    flex-direction: row;
    height: 100%;
    padding: 5px 10px 0 10px;

    .team + .team {
      margin-left: 14px;
    }

    .team {
      width: 123px;

      & > div {
        display: flex;
        align-items: center;
        width: auto;

        & + div {
          display: flex;
          flex-direction: row;
          align-content: center;
          margin-top: 3px;
        }
      }

      p {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
        text-align: left;
        color: ${props => props.theme.color.dark_200};
        opacity: 1;
        font-weight: 400 !important;
        line-height: 16px;

        &:hover {
          color: ${props => props.theme.color.dark_100};
          text-decoration: underline;
        }
      }

      .my {
        span {
          color: ${props => props.theme.color.dark_100};
          font-weight: bold;
          opacity: 1;
        }

        .imgChamp {
          border-radius: 50%;
        }
      }

      .myTogether {
        span {
          color: #6A5EDC;
          font-weight: bold;
          opacity: 1;
        }

        .imgChamp {
          border-radius: 50%;
        }
      }

      .together {
        span {
          color: #6A5EDC;
          opacity: 1;
        }
      }
    }

    .imgChamp {
      width: 22px;
    }

  }

  .flex_column7 {
    width: 26px;
    border-right: 0;

    button {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        bottom: 7px;
        left: 50%;
        margin-left: -7px;
        width: 14px;
        opacity: ${props => props.theme.color.opacity_1_04};
      }
    }
  }
`;

export const MatchDetailContainer = styled.div`
  background-color: ${props => props.theme.color.site_main_color};

  .analysis_ai {
    display: flex;
    padding: 14px;

    .total {
      width: 187px;
      background-color: ${props => props.theme.color.site_main_color};
      border: 1px solid ${props => props.theme.color.default_border_color};

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 6px 5px 11px;
        font-size: 12px;
      }

      .list {
        display: none;
        font-size: 12px;

        &.selected {
          display: block;
        }

        &__item {
          display: flex;
          align-items: center;
          width: 100%;
          height: 46px;

          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }

          &.win {
            background-color: ${props => props.theme.color.blue_100};
          }

          &.lose {
            background-color: #eedddf;
          }
        }

        .rating {
          width: 19px;
        }

        .champ {
          position: relative;
          width: 28px;
          margin-left: 4px;

          &.my {
            .box {
              &::after {
                content: '';
                position: absolute;
                top: -3px;
                left: -3px;
                display: block;
                width: 100%;
                height: 100%;
                border: 3px solid #ffdd00;
                border-radius: 50%;
                z-index: 5;
              }
            }
          }

          .box {
            position: relative;
            z-index: 5;
            display: block;
          }
        }

        .score {
          width: 32px;
          margin-left: 2px;
          font-size: 18px;
          font-weight: 700;
        }

        .chart {
          width: 90px;
          margin-left: 5px;
          flex-direction: column;

          .num {
            margin-bottom: 8px;
          }

          .bar {
            position: relative;
            width: 80px;
            height: 8px;
            margin: 0 auto;
            background: ${props => props.theme.color.site_main_color};
            border-radius: 3px;

            &.blue .line {
              background-color: #539dff;
            }

            &.red .line {
              background-color: #e96ba2;
            }
          }

          .line {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 3px;
          }
        }
      }

      .imgChamp {
        width: 28px;
        border-radius: 50%;
      }

      .crown {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -11px;
        background-size: 85%;
        z-index: 10;
      }
    }

    .result {
      flex: 1;
      margin-left: 13px;
      background-color: ${props => props.theme.color.site_main_color};
      border: 1px solid ${props => props.theme.color.default_border_color};

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 30px;

        .team {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: ${props => props.theme.color.red_100};

          strong {
            display: inline-block;
            margin: 0 5px;
            font-size: 22px;
            font-weight: 700;

            &.win {
              color: #5682db;
            }

            &.lose {
              color: #de4469;
            }
          }
        }

        .center {
          flex: 1;
        }

        .main {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;

          > div {
            display: flex;
            align-items: center;
          }

          .vs {
            margin: 5px 6px 0;
            font-weight: 700;
          }

          .red {
            width: 200px;
            justify-content: flex-end;

            .golds {
              margin-left: 15px;
              flex-direction: row-reverse;

              img {
                margin-right: 8px;
              }
            }
          }

          .blue {
            width: 200px;
            justify-content: flex-end;

            .golds {
              margin-right: 15px;
              flex-direction: row;

              img {
                margin-left: 8px;
              }
            }
          }

          .win {
            color: #5682db;
          }

          .lose {
            color: #de4469;
          }

          .golds {
            display: flex;
            align-items: flex-end;
          }

          .kills {
            font-size: 24px;
            font-weight: 500;
          }
        }

        .sub {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 8px;
          font-size: 12px;

          .time {
            margin: 0 4px;
            font-weight: 700;
            color: rgba(49, 49, 49, 0.75);
          }

          .objs {
            min-width: 200px;
            display: flex;
            align-items: center;

            &.red {
              flex-direction: row;
            }

            &.blue {
              flex-direction: row-reverse;
            }

            img {
              margin: 0 2px;
            }
          }
        }
      }

      .content {
        .t_head {
          display: flex;
          align-items: center;
          height: 34px;
          border-top: 1px solid ${props => props.theme.color.default_border_color_A5};
          background-color: ${props => props.theme.color.site_main_color};
          font-size: 12px;

          .team {
            flex: 1;
            display: flex;
            align-items: center;

            span {
              display: block;
              text-align: center;
            }
          }

          .buttons {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 192px;

            button {
              width: 54px;
              height: 24px;
              background-color: ${props => props.theme.color.site_main_color};
              border: 1px solid ${props => props.theme.color.background_5C6};
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
              border-radius: 3px;
              transition: all 0.2s linear;

              &.selected {
                background-color: ${props => props.theme.color.background_5C6};
                color: ${props => props.theme.color.site_main_color};
              }
            }
          }
        }

        .t_body {
          position: relative;
          display: flex;
          font-size: 12px;

          > ul {
            flex: 1;

            &.win {
              background-color: ${props => props.theme.color.background_CD};
            }

            &.lose {
              background-color: #efdee0;
            }
          }

          .team {
            display: flex;
            align-items: center;
            border-top: 1px solid ${props => props.theme.color.default_border_color_A5};

            > div {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 79px;
            }
          }
        }

        .team_column1 {
          width: 105px;

          .cbox {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            img {
              width: 20px;
              margin: 1px;

              &:last-of-type {
                border: 0;
              }
            }
          }

          .vision {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;

            span {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 2px;

              img {
                margin-right: 1px;
              }
            }
          }
        }

        .team_column2 {
          width: 80px;

          p {
            &.type2 {
              ////background-color: red;
              color: ${props => props.theme.color.font_rgba49};

              span {
                display: inline-block;
                margin: 0 4px;
                color: ${props => props.theme.color.dark_100};
              }
            }

            &.type3 {
              margin-top: 4px;
              color: ${props => props.theme.color.font_rgba49};

              span {
                display: inline-block;
                margin-right: 4px;
                color: ${props => props.theme.color.dark_100};
              }
            }

            &.type4 {
              margin-top: 4px;
              color: ${props => props.theme.color.font_rgba49};

              span {
                display: inline-block;
                margin-right: 4px;
                color: ${props => props.theme.color.dark_100};
              }
            }
          }
        }

        .team_column3 {
          width: 105px;

          a {
            display: block;
            width: 100%;
          }

          dl {
            display: flex;
            align-items: center;
            justify-content: center;

            & + p {
              width: 100%;
              margin-top: 2px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: break-word;
              line-height: 16px;

              &.away {
                color: ${props => props.theme.color.red_300};
              }

              &.my {
                font-weight: bold;
              }
            }
          }

          dt {
            position: relative;
            margin: 0 1px;

            &.away {
              &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4) url(/images/icon_exit_cross.svg) no-repeat center center;
                z-index: 5;
              }
            }

            &.my {
              &::before {
                content: '';
                position: absolute;
                top: -3px;
                left: -3px;
                display: block;
                width: 100%;
                height: 100%;
                border: 3px solid #ffdd00;
                z-index: 5;
              }
            }

            span {
              position: absolute;
              bottom: 0;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 15px;
              height: 15px;
              background-color: rgba(0, 0, 0, 0.7);
              border: 1px solid rgba(255, 236, 236, 0.7);
              font-size: 10px;
              font-weight: 400;
              color: ${props => props.theme.color.site_main_color};
            }
          }

          dd {
            width: 20px;
            margin: 0 1px;

            div + div {
              margin-top: 2px;

              .imgRune {
                padding: 2px;
              }
            }
          }
        }

        .team_column4 {
          width: 60px;

          h4 {
            font-size: 25px;
            font-weight: 700;
            cursor: pointer;

            &:hover {
              animation: hoverScale 150ms linear;
              transform: scale(1.2);
            }

            @keyframes hoverScale {
              0% {
                transform: scale(1);
              }
              100% {
                transform: scale(1.2);
              }
            }
          }

          p {
            margin: 5px 0 4px;

            &.away {
              color: ${props => props.theme.color.red_300};
            }
          }

          .tier_badge {
            width: 24px;
            font-size: 11px;
            font-weight: 400;
            height: 14px;
            border-radius: 5px;
          }
        }

        .compare {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 192px;

          .box {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 68px;
            margin: 12px 0;
            padding: 0 14px;
            background-color: rgba(255, 255, 255, 0.6);
            border-radius: 3px;

            &.damage_box {
              padding: 0;
            }

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 5;
              display: block;
              width: 0;
              height: 79px;
              margin-top: -40px;
              border-left: 1px dotted rgba(0, 0, 0, 0.5);
            }
          }

          .lane {
            position: relative;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 21px;
            height: 21px;
            margin: 0 auto;
            background: ${props => props.theme.color.site_main_color};
            border-radius: 2px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);

            img {
              margin-top: 1px;
            }
          }

          .influence {
            position: relative;
            z-index: 10;
            width: 100%;
            height: 20px;
            margin-top: 10px;

            .bar {
              position: absolute;
              top: 0;
              display: block;
              width: 100%;
              height: 8px;

              &.win {
                background: #4481d6;
              }

              &.lose {
                background: #df6a52;
              }
            }
          }

          .damage {
            position: relative;
            z-index: 10;
            width: 100%;
            height: 20px;
            margin-top: 8px;

            .bar {
              position: absolute;
              top: 0;
              display: block;
              width: 50%;
              height: 8px;

              &.win i {
                background: #4481d6;
              }

              &.lose i {
                background: #df6a52;
              }

              i {
                position: absolute;
                top: 0;
                display: block;
                height: 100%;
              }
            }

            .txt {
              &.red {
                right: 7px;
              }

              &.blue {
                left: 7px;
              }
            }
          }

          .txt {
            display: block;
            position: absolute;
            top: 12px;
          }

          .red {
            right: 0;

            i {
              right: 0;
            }
          }

          .blue {
            left: 0;

            i {
              left: 0;
            }
          }
        }
      }

      .red {
        flex-direction: row-reverse;

        .team_column3 {
          dl {
            flex-direction: row;

            & + p {
              padding-left: 10px;
              text-align: left;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .blue {
        flex-direction: row;

        .team_column3 {
          dl {
            flex-direction: row-reverse;

            & + p {
              padding-right: 10px;
              text-align: right;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .imgMonster {
        display: none;
        width: 13px;

        &.dragon {
          display: block;
        }
      }

      .imgChamp {
        width: 42px;
      }

      .imgDiv {
        border-radius: 8px;
      }

      .imgSpell {
        width: 20px;
        border-radius: 3px;
      }

      .imgRune {
        width: 20px;
      }
    }

    .toggle {
      position: relative;
      width: 90px;

      &__btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 3px 8px 6px 8px;
        font-size: 12px;
        cursor: pointer;

        &::after {
          content: '';
          overflow: hidden;
          display: inline-block;
          border-style: solid;
          border-width: 3px;
          width: 0;
          height: 0;
          transition: all 0.3s linear;
          margin-top: 4px;
          margin-left: 9px;
        }
      }

      &__list {
        position: absolute;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        max-width: 536px;
        max-height: 140px;
        margin-top: 5px;
        padding: 5px 0;
        background: ${props => props.theme.color.site_main_color};
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
        border: 1px solid ${props => props.theme.color.dark_100};
        z-index: 10;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__list-item {
        display: flex;
        align-items: center;
        height: 25px;
        padding: 0 10px;
        font-size: 12px;
        color: #ababab;
        cursor: pointer;

        &:hover,
        &.selected {
          color: #3362e5;
          background: #dbe8ff;
        }
      }
    }
  }

  .analysis_time {
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.color.site_main_color}.tab {
      background-color: #eaecf1;
      border: 1px solid ${props => props.theme.color.default_border_color_A5};
      font-size: 12px;

    }.rating {
      display: flex;
      flex-direction: column;
      width: 25px;

      .crown {
        margin-bottom: 2px;
      }
    }.champ {
      margin-left: 6px;
    }.score {
      margin-left: 6px;
      font-size: 20px;
      font-weight: 700;
    }.chart {

    }
  }

}

.react-tabs__tab-panel {
  position: relative;

  > div {
    flex: 1;
  }
}

.chart_dot {
  position: absolute;
  top: -25px;
  left: 50%;
  width: 50px;
  padding: 3px 0;
  margin-left: -25px;
  background: ${props => props.theme.color.site_main_color};
  border-radius: 4px;
  color: ${props => props.theme.color.dark_100};
  line-height: 1;

  &.good {
    border: 1px solid ${props => props.theme.color.green_300};
    color: ${props => props.theme.color.green_300};
  }

  &.bad {
    border: 1px solid ${AI_SCORE_BAD_TEXT};
    color: ${AI_SCORE_BAD_TEXT};
  }

  &.one_line {
    top: -10px;
  }

  .score {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
  }

  .diff {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    font-size: 11px;
    font-weight: 400;
    color: ${props => props.theme.color.dark_100};

    i {
      &.positive {
        &::before {
          background-image: url('/images/ico_graph_arrow_up_green.svg');
        }
      }

      &.negative {
        &::before {
          background-image: url('/images/ico_graph_arrow_down_red.svg');
        }
      }

      &::before {
        content: '';
        display: inline-block;
        width: 7px;
        height: 5px;
        margin-right: 1px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

.highcharts-plot-band-label {
  display: block;
}

.marker_score {
  &.man {
    top: -16px;
  }
}

.legend {
  position: absolute;
  top: 35px;
  left: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 24px;
  background: ${props => props.theme.color.site_main_color};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  font-size: 12px;
  font-weight: 700;

  i {
    display: inline-block;
    width: 50px;
    height: 0;
    margin-left: 8px;
    border: 1px dashed #a878d8;
  }
}

.timeline {
  bottom: 26px;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 48px;
  background-color: transparent;

  .team {
    flex: 1;

    &.win {
      background: ${props => props.theme.color.blue_300};
    }

    &.lose {
      background: ${props => props.theme.color.red_400};
    }
  }

  .cade {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .deca {
    position: relative;
    width: calc(100% - 20px);
    height: 100%;
    margin: 0 10px;
  }

  .monster {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-left: -9px;

    img {
      width: 100%;
    }
  }
}


.loading {
  height: 525px;

  &_spin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 525px;
  }
}

@keyframes progressbar {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
`;

export const ChartModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  &.openModal {
    .dimm,
    .modal {
      border-radius: 6px;
      overflow: hidden;
    }
  }

  &:focus {
    display: none;
  }

  .dimm {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(2px);
    z-index: 10;
  }

  .buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 780px;
    z-index: 20;

    button {
      position: absolute;
      width: 62px;
      height: 62px;
      margin-top: -26px;
      background-position: center;
      background-repeat: no-repeat;

      &.prev {
        top: 0;
        left: -72px;
        background-image: url('/images/icon_s_collapse_arrow.svg');
      }

      &.next {
        top: -5px;
        right: -72px;
        background-image: url('/images/icon_s_collapse_arrow.svg');
        transform: rotate(180deg);
      }
    }
  }

  .modal {
    background-color: ${props => props.theme.color.site_main_color};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 780px;
    text-align: center;
    z-index: 20;

    .description {
      position: absolute;
      bottom: -25px;
      left: 10px;

      p {
        font-size: 14px;
        font-weight: 700;
        color: #fff;
      }
    }

    .title {
      position: relative;

      h3 {
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        font-weight: 700;

        span {
          display: inline-block;
          margin-left: 5px;
          font-size: 14px;
          font-weight: 700;
          color: ${props => props.theme.color.dark_200};
          letter-spacing: 1px;
        }
      }

      .winrate {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;

        .team {
          display: flex;
          align-items: flex-end;

          &.win {
            h4 {
              color: ${props => props.theme.color.blue_100};
            }
          }

          &.lose {
            h4 {
              color: ${props => props.theme.color.red_100};
            }
          }

          h4 {
            margin: 0 6px;
            font-size: 28px;
            font-weight: 700;

            span {
              display: inline-block;
              margin-left: 3px;
              font-size: 16px;
              font-weight: 400;
              color: ${props => props.theme.color.dark_200};
            }
          }

          .updown {
            margin-bottom: 5px;
          }
        }

        .woose_bar {
          position: relative;
          display: flex;
          align-items: center;
          width: 240px;
          margin: 15px 10px 0;
          border-radius: 100px;
          overflow: hidden;

          .bar {
            height: 8px;

            &.win {
              background-color: ${props => props.theme.color.blue_100};
            }

            &.lose {
              background-color: ${props => props.theme.color.red_100};
            }
          }
        }
      }
    }

    .result {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;
      border-top: 1px solid ${props => props.theme.color.default_border_color};
      border-bottom: 1px solid ${props => props.theme.color.default_border_color};

      .team {
        position: relative;
        width: 360px;

        &.blue {
          ul {
            justify-content: flex-end;
          }

          h4 {
            border-radius: 0 100px 100px 0;
            justify-content: flex-start;
          }
        }

        &.red {
          ul {
            justify-content: flex-start;
          }

          h4 {
            border-radius: 100px 0 0 100px;
            justify-content: flex-end;
          }
        }

        &.win {
          h4 {
            background: ${props => props.theme.color.blue_100};
          }
        }

        &.lose {
          h4 {
            background: ${props => props.theme.color.red_100};
          }
        }

        h4 {
          position: absolute;
          top: 20px;
          left: 0;
          display: flex;
          align-items: center;
          width: 100%;
          height: 18px;
          padding: 0 10px;
          font-size: 12px;
          font-weight: 500;
          color: #fff;
          z-index: 5;
        }

        ul {
          position: relative;
          display: flex;
          z-index: 10;
        }

        ul li {
          width: 42px;
          margin: 0 11px;

          .imgChamp {
            width: 42px;
            height: 42px;
          }

          .imgDiv {
            border-radius: 7px;
          }
        }

        .score {
          .score_color {
            font-size: 12px;
            font-weight: 700;
          }

          display: block;
          margin: 4px 0 2px;
          text-align: center;
        }
      }
    }

    .view {
      display: flex;
      padding: 10px 14px;
      background-color: ${props => props.theme.color.white_201};

      .event_box {
        flex: 1;
        height: 276px;
        overflow-y: auto;
        overflow-x: hidden;

        ::-webkit-scrollbar {
          width: 5px;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #b8cddc;
        }

        ::-webkit-scrollbar-track {
          background-color: #dbe8ff;
        }
      }

      .event_item {
        display: flex;
        align-items: center;

        .event_column4 {
          background-color: ${props => props.theme.color.dark_200};
          border-radius: 6px;
          overflow: hidden;
        }

        > div {
          display: flex;
        }

        .event_column2 {
          border-radius: 6px;
          overflow: hidden;
        }

        &.win {
          .event_column1 {
            .num {
              border-color: ${props => props.theme.color.blue_100};
            }
          }

          .event_column2 {
            background-color: ${props => props.theme.color.blue_200};
          }

          .event_column3 {
            .vs {
              background-image: url('/images/ico_cross_graph_b.svg');
            }
          }


        }

        &.lose {
          .event_column1 {
            .num {
              border-color: ${props => props.theme.color.red_100};
            }
          }

          .event_column2 {
            background-color: ${props => props.theme.color.red_200};
          }

          .event_column3 {
            .vs {
              background-image: url('/images/ico_cross_graph_r.svg');
            }
          }

        }

        .event_column1 {
          width: 30px;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .num {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            background: #354052;
            border-width: 2px;
            border-style: solid;
            border-radius: 50%;
            font-size: 14px;
            font-weight: 700;
            color: #fff;
          }

          .time {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            font-size: 12px;
          }
        }

        .event_column2 {
          position: relative;
          align-items: flex-end;
          justify-content: flex-end;
          width: 267px;
          height: 60px;
          margin-left: 13px;
          padding: 3px 13px;

          .list {
            position: relative;
            z-index: 10;
            display: flex;
            flex-direction: row-reverse;

            > li {
              margin: 0 8px;
            }

            .imgChamp,
            .imgMonster {
              width: 36px;
            }
          }

          .killer {
            position: relative;
            z-index: 10;

            .imgDiv {
              width: 36px;
              height: 36px;
            }

            .imgChamp {
              width: 42px;
              height: 42px;
            }

            .imgDiv {
              border-radius: 6px;
            }
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 5;
            display: block;
            width: 100%;
            height: 16px;
            background-color: ${props => props.theme.color.site_main_color};
          }
        }

        .event_column3 {
          margin: 0 22px;

          .vs {
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
        }

        .event_column4 {
          position: relative;
          align-items: flex-end;
          justify-content: center;
          width: 50px;
          height: 60px;
          padding: 3px;

          .target {
            position: relative;
            z-index: 10;

            .imgChamp {
              width: 42px;
              height: 42px;
            }

            .imgDiv {
              border-radius: 7px;
            }
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 5;
            display: block;
            width: 100%;
            height: 16px;
            background-color: ${props => props.theme.color.site_main_color};
          }
        }

        .updown {
          margin-top: 6px;
        }
      }

      .event_item + .event_item {
        margin-top: 4px;
      }

      .map_box {
        position: relative;
        width: 276px;
        height: 276px;
        margin-left: 30px;
        background: url('/images/bg_map_tracking@2x.png') no-repeat center;
        background-size: cover;

        .mini {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: default;
          color: #fff;
        }

        .num {
          width: 24px;
          height: 24px;
          margin-top: -12px;
          margin-left: -12px;
          background: #354052;
          border-width: 2px;
          border-style: solid;
          border-radius: 50%;
          font-size: 14px;
          font-weight: 700;

          &:hover {
            z-index: 100;
          }

          &.win {
            border-color: ${props => props.theme.color.blue_100};
          }

          &.lose {
            border-color: ${props => props.theme.color.red_100};
          }
        }

        .destruction {
          width: 16px;
          height: 16px;
          margin-top: -8px;
          margin-left: -8px;

        }

        .icon {
          width: 16px;
          height: 16px;
          margin-top: -8px;
          margin-left: -8px;
        }
      }
    }

    .chart {
      position: relative;

      .timeline {
      }

      .plot_lines {
        display: block;
        width: 8px;
        height: 8px;
        transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
        background: #00d5b0;
        border: 1px solid #ffff00;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.57);
      }
    }

    .champ {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        background-color: rgba(0, 0, 0, 0.7);
        border: 1px solid rgba(255, 236, 236, 0.7);
        font-size: 10px;
        font-weight: 400;
        color: ${props => props.theme.color.empty_back_ground_color};
      }

      &.is_mine {
        .imgDiv {
          &::before {
            content: '';
            position: absolute;
            top: -3px;
            left: -3px;
            display: block;
            width: 100%;
            height: 100%;
            border: 3px solid #ffdd00;
            z-index: 5;
            border-radius: 9px 9px 0 9px;
          }
        }
      }

      .imgChamp,
      .imgMonster {
        width: 36px;
      }

      .imgEnv {
        border-radius: 4px;
      }
    }

    .updown {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;

      &.positive {
        i {
          display: inline-block;
          background-image: url('/images/ico_graph_arrow_up_green.svg');
        }
      }

      &.negative {
        i {
          display: inline-block;
          background-image: url('/images/ico_graph_arrow_down_red.svg');
        }
      }

      i {
        display: none;
        width: 7px;
        height: 5px;
        margin-left: 2px;
        margin-right: 2px;
        margin-top: 2px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  @keyframes modal-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const FloatContainer = styled.div`
  z-index: 99;
  width: 1150px;

  &.not_fixed {
    position: absolute;
    top: 10px;
  }

  &.fixed {
    position: fixed;
    top: 70px;
  }

  .float {
    position: absolute;
    width: 300px;

    &.left {
      top: 0;
      left: -340px;
    }

    &.right {
      top: 0;
      right: -320px;
    }

    & > div {
      border: 1px solid ${props => props.theme.color.default_border_color};
      background-color: ${props => props.theme.color.empty_back_ground_color};

      & + div {
        margin-top: 10px;
      }
    }
  }

  .advertising {
    height: auto;
  }

  .history {
    display: flex;

    h4 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      font-size: 14px;
      font-weight: 700;
      background-color: ${props => props.theme.color.empty_back_ground_color};
      border-bottom: 1px solid ${props => props.theme.color.default_border_color};
    }

    .box {
      flex: 1;
      background-color: #f5f5f5;

      ul {
        display: block;
        overflow-y: auto;
        padding: 6px 4px;
        width: 100%;
        height: 200px;

        ::-webkit-scrollbar {
          width: 1px;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #b8cddc;
        }

        ::-webkit-scrollbar-track {
          background-color: #dbe8ff;
        }

        li {
          width: 100%;
          margin: 0;

          & + li {
            margin-top: 4px;
          }
        }
      }

      .no_list {
        padding: 0 5px;
        font-size: 12px;
      }
    }

    .box + .box {
      border-left: 1px solid ${props => props.theme.color.default_border_color};
    }
  }

  .ai_score {
    h3 {
      display: flex;
      align-items: center;
      height: 38px;
      padding: 0 20px;
      font-size: 14px;
      font-weight: 700;
      background-color: ${props => props.theme.color.empty_back_ground_color};

      &::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 7px;
        background-color: ${props => props.theme.color.empty_back_ground_color};
        border: 2px solid #44bd9f;
        border-radius: 50%;
        box-sizing: border-box;
      }
    }

    .box {
      padding: 19px;
      background-color: #f5f5f5;

      p {
        margin-bottom: 10px;
        padding: 6px 8px 8px;
        background-color: ${props => props.theme.color.background_DD};
        border-radius: 8px;
        font-size: 12px;
        color: #3e414a;
        line-height: 1.4;
      }
    }
  }
`;

export const ChampContainer = styled.div`
  margin: 10px 0;
  border-radius: 6px;
  overflow: hidden;
  border: ${props => {
    let border = '1px';
    if (props.disableBorder) border = 0;
    return border;
  }} solid ${props => props.theme.color.default_border_color};


  .filter {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 20px;

    select {
      &:hover {
        background-color: ${props => props.theme.color.white_200};
      }

      width: 100%;
      height: 29px;
      padding: 0 8px;
      border: 0;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 8l4 4 4-4z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: center;
      cursor: pointer;
    }

    .rank_type {
      width: 113px;
    }

    .position_type {
      display: flex;
      align-items: center;
      margin-left: 28px;

      .btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        padding: 0 10px;
        background-color: ${props => props.theme.color.empty_back_ground_color};
        border: 1px solid #719893;
        border-radius: 3px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        font-size: 12px;
        font-weight: 500;
        color: #719893;
        cursor: pointer;
        transition: all 0.2s linear;

        &.selected {
          background: ${props => props.theme.color.gradient_green2};
          border: 1px solid #196068;
          color: ${props => props.theme.color.empty_back_ground_color};
          text-shadow: 0px 1px 0px rgba(25, 96, 104, 1);

          p {
            &::before {
              display: none;
            }
          }

          .selector {
            background-color: #296e65;
            transition: all 0.2s linear;

            .selected {
              background-color: #000;
            }
          }
        }

        p {
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            z-index: 10;
          }
        }

        &:last-of-type {
          margin-left: 18px;

          &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: -10px;
            width: 1px;
            height: 26px;
            background-color: rgba(112, 112, 112, 0.5);
          }
        }
      }

      .selector {
        display: flex;
        align-items: center;
        margin-left: 8px;
        font-size: 14px;
        font-weight: 500;
        color: ${props => props.theme.color.empty_back_ground_color};
        background-color: #e0e0e0;
        border-radius: 2px;
        padding: 2px 0;

        span {
          position: relative;
          display: inline-block;
          margin: 0 1px 0 2px;
          padding: 1px 4px;
          border-radius: 2px;
          transition: all 0.2s linear;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: -2px;
            display: inline-block;
            width: 1px;
            height: 10px;
            margin-top: -5px;
            background-color: rgba(255, 255, 255, 0.5);
          }

          &:first-of-type {
            padding: 1px 8px;

            &::after {
              display: none;
            }
          }
        }
      }
    }

    .champ_search {
      position: relative;
      width: 200px;
      margin-left: 10px;

      input {
        width: 100%;
        height: 29px;
        padding: 0 8px;
        border: 0;
        border-bottom: 1px solid ${props => props.theme.color.font_21};
        background-image: ${props => `url('${props.theme.icon.path}/ico_search_w.svg')`};
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: center;
        background-size: 18px 18px;
      }
    }

    .season_type {
      width: 160px;
      margin-left: 12px;
    }

    .reset {
      margin-left: 10px;

      button {
        width: 32px;
        height: 32px;
        background: ${props => props.theme.color.background_5C6} url('/images/ico_reset.svg') no-repeat center;
        border: 1px solid #474c72;
        border-radius: 3px;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
        font-size: 0;
        text-indent: -9999px;
      }
    }
  }

  .table {
    width: 100%;
    max-width: 1078px;
    text-align: center;

    thead {
      background: ${props => props.theme.color.white_100};
      border-top: 1px solid ${props => props.theme.color.default_border_color};
      border-bottom: 1px solid ${props => props.theme.color.default_border_color};

      th {
        position: relative;
        height: 44px;
        vertical-align: middle;

        &:last-of-type,
        &:first-of-type {
          .t-cell {
            border-left: 0;
          }
        }

        &.sort {
          cursor: pointer;
        }
      }

      .t-cell {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .name {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        color: ${props => props.theme.color.dark_200};

        i {
          font-weight: 500;
          color: ${props => props.theme.color.dark_200};
        }
      }

      .arrow {
        display: flex;
        flex-direction: column;
        width: 6px;
        height: 12px;
        margin-left: 7px;

        &::before {
          content: '';
          display: inline-block;
          flex: 1;
          background: url('/images/ico_graph_arrow_up.svg') no-repeat center top;
        }

        &::after {
          content: '';
          display: inline-block;
          flex: 1;
          background: url('/images/ico_graph_arrow_down.svg') no-repeat center bottom;
        }
      }

      .sort {
        &.descending {
          .arrow {
            &::before {
              opacity: 0.4;
            }
          }
        }

        &.ascending {
          .arrow {
            &::after {
              opacity: 0.4;
            }
          }
        }
      }

      .bottom {
        th {
          padding: 0 2px;
        }

        .t-cell {
          padding: 3px 5px;
          border-left: 0;
          background-color: rgba(255, 255, 255, 0.8);
        }

        .name {
          font-size: 11px;
          font-weight: 400;
        }

        .arrow {
          margin-left: 2px;
        }
      }
    }

    tbody {

      tr:last-of-type {
        .vs_champ {
          border-radius: 0 0 6px 6px;
        }
      }

      tr + tr {
        border-top: 1px solid ${props => props.theme.color.gray_200};
      }

      tr {
        &.open, &.close {
          cursor: pointer;

        }

        &.open {
          outline: 1px solid ${props => props.theme.color.green_100};
          outline-offset: -1px;
          background-color: ${props => props.theme.color.green_200};

          .arrow img {
            transform: rotate(180deg);
          }
        }

        &.close {

          &:hover {
            background-color: ${props => props.theme.color.white_200};
          }
        }

        &.detail {
          //border-left: 3px solid #989eb8;
          //border-right: 3px solid #989eb8;
          //border-bottom: 3px solid #989eb8;
          //outline: 3px solid #989eb8;
          //outline-offset: -3px;
        }
      }

      td {
        height: 52px;
        font-size: 14px;
        vertical-align: middle;
        //border-bottom: 1px solid #a5bed2;
      }

      .noraml {
        font-weight: 400;
      }

      .bold {
        font-weight: 500;
      }

      .champ {
        display: flex;
        align-items: center;
        padding: 0 5px;

        p {
          margin-left: 6px;
          font-weight: 500;
        }
      }

      .winlose {
        display: flex;
        align-items: center;
        padding: 0 10px;

        .graph {
          position: relative;
          width: 105px;
          height: 20px;
          border-radius: 4px;
          overflow: hidden;

          > span {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            height: 100%;
            font-size: 12px;
            font-weight: 500;
            color: #fff;
            overflow: hidden;

            i {
              display: inline-block;
            }
          }
        }

        .win {
          left: 0;
          justify-content: flex-start;
          background: ${props => props.theme.color.blue_100};
          border-radius: 4px 0px 0px 4px;

          i {
            padding-left: 5px;
          }
        }

        .lose {
          right: 0;
          justify-content: flex-end;
          background: ${props => props.theme.color.red_100};
          border-radius: 0px 4px 4px 0;

          i {
            padding-right: 5px;
          }
        }

        .winrate {
          margin-left: 17px;
        }
      }

      .kda {
        p {
          margin-top: 4px;
          font-size: 12px;
        }
      }

      .arrow {
        position: relative;
        width: 100%;
        height: 100%;

        img {
          width: 14px;
        }
      }

      .vs {
        font-size: 14px;
        font-weight: bold;
        color: ${props => props.theme.color.green_100};
      }

      .vs_champ {
        background-color: ${props => props.theme.color.green_200};
        padding: 11px 35px 14px;
        border: 1px solid ${props => props.theme.color.green_100};
          // outline: 1px solid ${props => props.theme.color.green_100};
        // outline-offset: -1px;
        margin-top: -1px;

        .list_wrap {
          overflow: hidden;
          width: 100%;
          margin-top: 3px;
          border: 1px solid ${props => props.theme.color.default_border_color};
          border-radius: 6px;


          &.open {
            max-height: inherit;
          }
        }

        .list_more {
          width: 100%;
          height: 31px;
          background: ${props => props.theme.color.site_main_color};
          font-size: 14px;
          font-weight: 500;
          color: ${props => props.theme.color.dark_200};
          border-top: 1px solid ${props => props.theme.color.gray_200};

          &:hover {
            background-color: ${props => props.theme.color.white_200};
          }
        }

        .list {
          display: table;
          width: 100%;

          li + li {
            border-top: 1px solid ${props => props.theme.color.gray_200};
          }
        }

        .list > li {
          display: table-row;
          height: 40px;
          background: ${props => props.theme.color.site_main_color};


          .td {
            display: table-cell;
            vertical-align: middle;
          }

        }
      }


      .lane {
        padding: 11px 30px 14px 33px;
      }

      .no_data {
        height: 492px;
        border-bottom: 0;
      }
    }

    .imgChamp {
      width: 34px;
    }

    .imgDiv {
      border-radius: 5px;
    }

    .imgLane {
      width: 20px;
    }
  }

  .loading {
    height: 550px;

    &_spin {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 550px;
    }
  }

  .champNameWrapper {
    width: 100px;
    text-align: left;
  }
`;

export const IngameContainer = styled.div`
  min-height: 560px;
  margin: 10px 0;

  &.on {
    background-color: transparent;
    border-radius: 0;
  }

  &.off {
    background-color: ${props => props.theme.color.empty_back_ground_color};
    border-radius: 10px;
  }

  .not_playing,
  .not_supported {
    height: 360px;
    background-color: ${props => props.theme.color.empty_back_ground_color};
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    button {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
    }

    p {
      line-height: 30px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 700;
    }

  }

  .overview {
    height: 160px;
    padding: 12px 15px;
    background-color: ${props => props.theme.color.background_F8};
    border: 1px solid ${props => props.theme.color.default_border_color};

    .title {
      display: flex;
      align-items: center;

      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 61px;
        height: 27px;
        background: #ea5a5a 0% 0% no-repeat padding-box;
        border-radius: 5px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        font-size: 16px;
        font-weight: 700;
        color: ${props => props.theme.color.empty_back_ground_color};
        letter-spacing: 1px;
      }

      p {
        margin: 0 17px 0 13px;
        font-weight: 700;
      }

      dl {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
      }

      dd {
        position: relative;
        margin-left: 8px;
        padding-left: 9px;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          display: block;
          width: 1px;
          height: 10px;
          margin-top: -5px;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .average {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 80px;
      margin: 13px 0 16px;
      padding: 6px 8px;
      background: ${props => props.theme.color.empty_back_ground_color};
      border-radius: 6px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

      .team {
        flex: 1;
        display: flex;
        align-items: center;
        //&.blue {
        //  flex-direction: row;
        //  padding-right: 100px;
        //  .name {
        //    flex:1;
        //    display: flex;
        //    margin-left: 3rem;
        //    color: #5682db;
        //  }
        //  .cont {
        //    text-align: left;
        //  }
        //  .winrate {
        //    display: flex;
        //    flex-wrap: nowrap;
        //    align-items: flex-end;
        //    strong {
        //      color: #5682db;
        //    }
        //    .winrate_percent {
        //      margin-left: 3px;
        //     margin-bottom: 6px;
        //    }
        //  }
        //}
        &.red {
          flex-direction: row-reverse;
          //padding-left: 100px;
          .name {
            flex-direction: row-reverse;
            display: flex;
            flex-grow: 1;
            color: #de4469;
          }

          .cont {
            text-align: right;
          }

          .winrate {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-end;

            strong {
              color: #de4469;
            }

            .winrate_percent {
              margin-left: 3px;
              margin-bottom: 6px;
            }
          }
        }

        .name {
          font-size: 28px;
          font-weight: 700;
        }

        .cont {
          min-width: 150px;

          .avg {
            padding: 2px 10px;
            background-color: rgba(0, 70, 162, 0.08);
            border-radius: 7px;

            p {
              font-size: 12px;
              line-height: 20px;

              strong {
                font-weight: 500;
              }
            }
          }

          .ban {
            display: flex;
            flex-direction: row;
            margin: 7px 7px 1px;
          }

          .imgChamp {
            width: 22px;
            margin: -3px;
          }

          .imgDiv {
            border-radius: 3px;
          }

          .tier_badge {
            width: 24px;
            height: 14px;
            font-size: 11px;
            font-weight: 400;
            border-radius: 5px;
            line-height: 12px;
          }
        }

        .winrate {
          font-size: 24px;
          font-weight: 500;
          color: ${props => props.theme.color.font_rgba49};

          strong {
            font-size: 52px;
            font-weight: 700;
          }
        }
      }

      .predict {
        position: absolute;
        top: -40px;
        left: 50%;
        width: 132px;
        height: 132px;
        background-color: #fafbfc;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 50%;
        transform: translateX(-50%);

        > div {
          //position: relative;
          //z-index: 5;
        }

        > span {
          //position: absolute;
          //top: 50%;
          //left: 50%;
          //z-index: 10;
          //display: flex;
          //text-align: center;
          //align-items: center;
          //justify-content: center;
          //width: 80px;
          //height: 80px;
          //background-color: #fafbfc;
          //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
          //border-radius: 50%;
          //transform: translate(-50%, -50%);
          //font-size: 19px;
          //font-weight: 700;
          //line-height: 1.2;
        }
      }
    }
  }

  .content {
    margin: 10px 0;
    background-color: ${props => props.theme.color.empty_back_ground_color};
    border: 1px solid ${props => props.theme.color.default_border_color_A5};

    .t_head {
      .row {
        display: flex;
        align-items: center;
        width: calc(100% - 36px);
        height: 35px;
        text-align: center;
      }

      .team {
        flex: 1;
        display: flex;
        align-items: center;

        &.blue {
          flex-direction: row;
        }

        &.red {
          flex-direction: row-reverse;
        }
      }

      .center {
        min-width: 132px;
      }

      .tit {
        font-size: 12px;
        font-weight: 700;

        span {
          display: block;
          margin-top: 2px;
          font-size: 11px;
          font-weight: 400;
          color: ${props => props.theme.color.font_4908};
        }
      }

      .item1 {
        width: 220px;
      }

      .item2 {
        width: 185px;
        margin: 0 5px;
      }

      .item3 {
        position: relative;
        width: 65px;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          display: block;
          width: 1px;
          height: 8px;
          margin-top: -4px;
          background-color: ${props => props.theme.color.default_border_color_A5};
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }
      }
    }

    .t_body {
      min-height: 400px;

      .button {
        position: absolute;
        top: 0;
        right: 0;
        width: 36px;
        height: 100%;
        border-left: 1px solid rgba(0, 0, 0, 0.07);

        button {
          position: relative;
          width: 100%;
          height: 100%;
        }

        img {
          position: absolute;
          bottom: 14px;
          left: 50%;
          margin-left: -7px;
          width: 14px;
          opacity: 0.4;
        }
      }

      .row {
        position: relative;
        z-index: 10;
        display: flex;
        width: calc(100% - 36px);
        height: 130px;
        padding: 10px;
        text-align: center;
      }

      .team {
        position: relative;
        flex: 1;
        display: flex;
        height: 100%;

        .imgChamp {
          width: 54px;
          height: 54px;
        }

        .imgDiv {
          border-radius: 9px;
        }

        &.blue {
          flex-direction: row;

          .champ_data {
            flex-direction: row-reverse;

            .txt {
              text-align: right;

              p {
                flex-direction: row;

                > em:last-of-type {
                  margin-left: 10px;
                }
              }
            }
          }

          .rune_data {
            flex-direction: row-reverse;

            .runes {
              flex-direction: row-reverse;
              margin-left: 9.5px;

              .twrap {
                margin-left: 3px;

                &:last-of-type {
                  margin-left: 0;
                }
              }

              &:last-of-type {
                margin-left: 0;
              }
            }
          }

          .profile_data {
            margin-left: 10px;

            a {
              &:hover {
                text-decoration: underline;
              }

            }

            text-align: left;

            .tags {
              left: 0;
              flex-direction: row;
              justify-content: flex-start;

              span + span {
                margin-left: 5px;
              }
            }

            .texts dl {
              justify-content: flex-start;
            }

            .promos {
              margin-right: 55px;
            }
          }

          .summoner_info {
            flex-direction: row;

          }
        }

        &.red {
          flex-direction: row-reverse;

          .champ_data {
            flex-direction: row;
            //.txt {
            //  text-align: left;
            //  p {
            //    flex-direction: row-reverse;
            //    > em:last-of-type {
            //      margin-right: 10px;
            //    }
            //  }
            //}
          }

          .rune_data {
            flex-direction: row;

            .runes {
              flex-direction: row;
              margin-right: 9.5px;

              .twrap {
                margin-right: 3px;

                &:last-of-type {
                  margin-right: 0;
                }
              }

              &:last-of-type {
                margin-right: 0;
              }
            }
          }

          .profile_data {
            margin-right: 10px;
            text-align: right;

            a {
              &:hover {
                text-decoration: underline;
              }
            }

            .tags {
              right: 0;
              flex-direction: row-reverse;
              justify-content: flex-end;

              span + span {
                margin-right: 5px;
              }
            }

            .texts dl {
              justify-content: flex-end;
            }

            .promos {
              margin-left: 55px;
            }
          }

          .summoner_info {
            flex-direction: row-reverse;
          }
        }
      }

      .center {
        width: 240px;
      }

      .ingame_list {
        position: relative;
      }

      .ingame_item {
        position: relative;
        border-top: 1px solid ${props => props.theme.color.default_border_color_A5};

        &:nth-of-type(even) {
          background-color: ${props => props.theme.color.background_F1};
        }

        &.complete {
          &::after {
            display: none;
          }
        }

        &.incomplete {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 20;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.8);
          }
        }

        &.open {
          background-color: ${props => props.theme.color.background_lose_hover};

          &::after {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 10;
            display: block;
            width: calc(100% + 2px);
            height: 100%;
            border: 3px solid rgb(93, 156, 235);
            box-sizing: border-box;
            z-index: 5;
          }

          .button {
            z-index: 10;

            img {
              transform: rotate(180deg);
            }
          }
        }

        .loading {
          position: absolute;
          z-index: 30;

          .dank-ass-loader {
            margin-top: 0;
          }
        }
      }

      .ingame_detail {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 36px);
        text-align: center;

        h4 {
          width: 100%;
          padding: 9px 0;
          background-color: ${props => props.theme.color.empty_back_ground_color};
          font-size: 12px;
          font-weight: 700;

          span {
            font-weight: 400;
            color: ${props => props.theme.color.font_rgba49};
          }
        }
      }
    }

    .summoner_info {
      display: flex;
      width: 210px;

      .icon_data {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 60px;
        padding-bottom: 27px;

        p {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px 5px;
          background-color: ${props => props.theme.color.background_DD};
          border-radius: 2px;

          span {
            display: inline-block;
            margin-top: 1px;
            margin-left: 3px;
            font-size: 11px;
            font-weight: 500;
            text-transform: uppercase;
          }

          &.not_most {
            span {
              color: ${props => props.theme.color.red_300};
            }

            i {
              display: inline-block;
              width: 14px;
              height: 14px;
              margin-left: 4px;
              font-size: 0;
              text-indent: -9999px;
              background: url('/images/icon_error.svg') no-repeat center;
            }
          }
        }

        .duo {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          display: inline-block;
          text-align: center;
          height: 18px;
          padding: 0 4px;
          font-size: 11px;
          border-radius: 3px;
          line-height: 16px;
          background: #f6eb86;
          border: 1px solid #e0ae47;
        }
      }

      .profile_data {
        padding-top: 22px;
        position: relative;
        flex: 1;

        .tags {
          display: flex;
          align-content: center;

          & > span {
            flex: 0 0 auto;
            display: inline-block;
            text-align: center;
            height: 18px;
            padding: 0 4px;
            font-size: 11px;
            color: ${props => props.theme.color.empty_back_ground_color};
            border-radius: 3px;
            line-height: 16px;

            &.win {
              background-color: #15b95d;
              border: 1px solid #2a9454;
            }

            &.lose {
              background-color: #e37e74;
              border: 1px solid #a85c54;
            }
          }
        }

        .promos {
          margin-top: 2px;

          span {
            width: 13px;
            height: 13px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
            font-size: 0;
            text-indent: -9999px;
          }
        }

        .texts {
          line-height: 20px;

          &.tag_on {
            padding-top: 22px;
          }

          &.tag_off {
            padding-top: 22px;
          }

          a {
            display: block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-wrap: break-word;
            font-size: 14px;
            font-weight: 500;
          }

          dl {
            display: flex;
            align-items: center;
            font-size: 10px;
            color: rgba(49, 49, 49, 0.4);
          }

          .t1 {
            dt {
              display: flex;
              align-items: center;
              justify-content: center;

              span {
                display: inline-block;
                margin-right: 4px;
                font-size: 14px;
                font-weight: 500;
              }
            }

            dd {
              margin-left: 3px;
              font-size: 12px;
              color: ${props => props.theme.color.dark_100};
            }
          }

          .t2 {
            dt {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              color: ${props => props.theme.color.font_rgba49};
              margin-right: 5px;

              span {
                display: inline-block;
                margin-right: 2px;
                font-size: 14px;
                font-weight: 700;
                color: #259a7e;
              }
            }

            dd {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 4px;
              font-size: 12px;
              color: ${props => props.theme.color.font_rgba49};

              span {
                display: inline-block;
                margin-right: 2px;
                color: ${props => props.theme.color.dark_100};
              }
            }
          }
        }

        .pro {
          display: block;
          font-size: 12px;
          color: #007edf;
          line-height: 14px;
        }
      }

      .loading {
        position: relative !important;
      }
    }

    .game_info {
      width: 196px;
      margin: 0;
      padding-top: 22px;

      .champ_data {
        display: flex;
        flex: 1;
        //.txt {
        //  flex: 1;
        //  p {
        //    display: flex;
        //    align-items: center;
        //    justify-content: flex-end;
        //    font-size: 12px;
        //    color: rgba(49, 49, 49, 0.6);
        //    line-height: 18px;
        //    &.name {
        //      font-weight: 500;
        //    }
        //    strong {
        //      color: #313131;
        //    }
        //    .score_color {
        //      font-weight: 700;
        //    }
        //    .kda_color {
        //      font-weight: 400;
        //    }
        //    > em {
        //      display: block;
        //    }
        //  }
        //}
        .spell {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 0 6px;
        }
      }

      .rune_data {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .runes {
          position: relative;
          display: flex;
          align-items: center;

          img {
            position: relative;
            z-index: 10;
            background-color: rgb(255, 255, 255);
            border-radius: 50%;
            width: 14px;
            margin: 0 1.5px;

            &:first-of-type {
              margin-left: 0;
            }

            &:last-of-type {
              margin-right: 0;
            }

            &.imgRune {
              margin: 0;
            }
          }

          .twrap {
            z-index: 10;
            background-color: ${props => props.theme.color.empty_back_ground_color};
            border-radius: 50%;

            img {
              width: 18px;
            }
          }

          &::before {
            content: '';
            position: absolute;
            z-index: 5;
            top: 50%;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: #7ea1be;
          }
        }
      }
    }

    .score_info {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 18px;

      .score {
        display: flex;
        flex-direction: column;
        width: 50px;

        .score_color {
          font-size: 28px;
          font-weight: 700;
        }

        .rating {
          display: block;
          margin-top: 5px;
          font-size: 12px;
        }
      }

      .graph {
        flex: 1;
        padding: 0 10px;

        .number {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-size: 10px;

            span {
              font-size: 12px;
            }
          }
        }

        .bar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          height: 8px;
          margin-top: 3px;

          p {
            height: 100%;

            &.blue {
              background-color: #4481d6;
            }

            &.red {
              background-color: #df6a52;
            }
          }
        }
      }
    }

    .counter_info {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      margin-top: 9px;
      background-color: rgba(0, 70, 162, 0.08);
      border-radius: 7px;

      .counter {
        display: flex;
        align-items: center;

        &.blue {
          flex-direction: row-reverse;
        }

        &.red {
          flex-direction: row;
        }

        p {
          margin: 0 7px;
          font-size: 11px;
          color: ${props => props.theme.color.font_rgba49};

          span {
            font-weight: 500;
          }
        }

        img {
          width: 24px;
          border-radius: 50%;
        }
      }

      .vs {
        width: 64px;
        margin: 0 9px;

        dt {
          font-size: 12px;
          color: ${props => props.theme.color.green_100};
        }

        dd {
          font-size: 11px;

          span {
            font-size: 10px;
          }
        }
      }
    }

    .detail_info {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 12px;
      background-color: ${props => props.theme.color.background_F1};
      border-top: 1px solid ${props => props.theme.color.border_A5};

      .match_list {
        flex: 1;
        display: flex;
      }

      .match_item {
        &:last-of-type {
          .box {
            border-right: 1px solid ${props => props.theme.color.border_A5};
          }
        }
      }

      .box {
        width: 46px;
        height: 100px;
        padding: 4px;
        text-align: center;
        border: 1px solid ${props => props.theme.color.border_A5};
        border-right: 0;

        &.win {
          background-color: ${props => props.theme.color.background_win};
        }

        &.lose {
          background-color: ${props => props.theme.color.background_lose};
        }

        .champ {
          .imgChamp {
            width: 42px;
            height: 42px;
          }

          .imgDiv {
            border-radius: 7px;
          }

          .imgLane {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: ${props => props.theme.color.dark_100};
          }
        }

        .score {
          margin: 4px 0;
          font-size: 18px;
          font-weight: 700;
        }

        .kda {
          margin-bottom: 3px;

          .perfect {
            font-size: 11px;
          }
        }

        .rating {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 12px;
            height: 9px;
            margin-right: 3px;
            margin-top: 1px;
          }

          .away {
            color: ${props => props.theme.color.red_300};
          }
        }
      }

      .time {
        margin-top: 5px;
        font-size: 10px;
        color: ${props => props.theme.color.font_rgba49};
        letter-spacing: -0.01em;
        text-align: center;
      }

      .match_num {
        width: 50px;
        margin: 0 13px;

        h5 {
          font-size: 26px;
          font-weight: 700;
        }

        p {
          font-size: 11px;
        }

        .winlose {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 12px;
          margin-bottom: 4px;

          dl {
            flex: 1;
          }

          dd {
            margin-top: 4px;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }

      .team {
        align-items: center;
        padding: 10px;

        &.blue {
          padding-right: 0;
        }

        &.red {
          padding-left: 0;
        }

        &:last-of-type {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 1px;
            height: 100%;
            background-color: ${props => props.theme.color.border_A5};
          }
        }
      }
    }

    .imgSpell {
      width: 24px;
      border-radius: 4px;
    }

    .imgRune {
      width: 18px;
    }

    .imgEmblem {
      width: 55px;
    }
  }
`;
