import React, {useMemo} from 'react';

import styled, {useTheme} from "styled-components";

import {useTranslation} from "react-i18next";
import {ColumnBox, FlexBox} from "../../../ui/styled";
import {ImageItem, ImageSpell} from "../../../common";
import {threedigits} from "../../../../function";
import ChampionsDetailBuildRuneBuild
    from "../../../ChampionsDetail/ChampionsDetailContent/ChampionsDetailBuildRuneBuild";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";
import BuildStartItem from "../../../ChampionsDetail/ChampionDetailMastery/BuildStartItem";
import BuildSkillSq from "../../../ChampionsDetail/ChampionDetailMastery/BuildSkillSq";
import useItemFunc from "../../../../common/hooks/useItemFunc";
import useTransBuildTitle from "../../../../common/hooks/useTransBuildTitle";
import useItemInfo from "../../../../common/hooks/useItemInfo";
import {useSelector} from "react-redux";

const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 100%;
  border-left: 1px solid ${props => props.theme.color.default_border_color};
  border-top: 1px solid ${props => props.theme.color.default_border_color};
  border-right: 1px solid ${props => props.theme.color.default_border_color};
  color: ${props => props.theme.color.font_5C};
  font-weight: bold;
  font-size: 12px;
  background-color: ${props => props.theme.color.site_main_color};
`;
const EmptyBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 160px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.background_F6};
  font-size: 14px;
`;
const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 70px;
`;
const BackgroundWrapper = styled.div`
  background-color: ${props => props.theme.color.site_main_color};
  overflow: hidden;
  border-radius: 6px;
  padding: 20px 0;
`;
const MarginBox = styled.div`
  margin-bottom: 10px;

  .imgItem {
    width: 32px;
    border-radius: 5px;
  }
`;

const ArrowBox = styled.div`
  display: flex;
  align-items: center;
  margin: 0 3px;
  height: 32px;
`;

const ItemMarginBox = styled.div`
  margin-right: 8px;
`


const RuneListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 344px;
`;

const TitleTag = styled.div`
  background-color: ${props => props.theme.color.green_200};
  color: ${props => props.theme.color.green_100};
  font-weight: bold;
  font-size: 14px;
  padding: 4px 10px;
  border-radius: 6px;
`;

const PickWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const PickText = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
  font-size: 12px;
`;
const GamesText = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.color.dark_300};
  margin-left: 2px;
`;
const PercentValueText = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: ${props => props.theme.color.dark_100};
  margin-left: 5px;
`;

const PickBar = styled.div`
  margin: 0 10px;
  width: 1px;
  height: 12px;
  background-color: ${props => props.theme.color.green_100};
  opacity: 0.5;
`;

const Percent = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-weight: bold;
  font-size: 12px;
  margin-left: 1px;
`;

const ValueText = styled.span`
  margin-left: 5px;
  font-size: 12px;
  color: ${props => {
    let color = props.theme.color.red_100;
    if (props.value >= 50) {
      color = props.theme.color.blue_100;
    }
    return color;
  }};
  font-weight: bold;
`;

const BuildText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_100};
  font-weight: 500;
  line-height: 14px;
  margin-top: 2px;
`;

const DetailText = styled.span`
  font-size: 11px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
  line-height: 13px;
  margin-top: 2px;
`;
const DetailTextBold = styled.span`
  font-size: 11px;
  color: ${props => props.theme.color.dark_100};
  font-weight: bold;
  line-height: 13px;
`;

const BoldBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BoldBorderBox = styled.div`
  .imgItem {
    box-sizing: content-box;
    border: 3px solid ${props => props.theme.color.green_100};
    border-radius: 4px;
  }
`;

const MarginLeft50 = styled.div`
  margin-left: 50px;
`;

const Margin5 = styled(ArrowBox)`
  height: 42px;
  margin: 0 5px;
`;
const BorderArrowBox = styled(ArrowBox)`
    height: 38px;
`;

const SpellBox = styled.div`
  & + & {
    margin-left: 5px;
  }
`;

const BuildRunes = ({coreList, champId, coreSelected, skillList,currentVersion}) => {
    const theme = useTheme();
    const {translateTitle} = useTransBuildTitle();
    const {getChampion} = useChampionFunc();
    const {isFinalItem} = useItemFunc();
    const {isSupItem} = useItemInfo();
    const spellData = useSelector((state) => state.champions.buildSpell);
    const buildVersion = useSelector((state) => state.champions.championVersions);
    const currentChampion = useMemo(() => getChampion(champId), [champId]);
    const {t} = useTranslation();
    const selectedItem = useMemo(() => {
        const obj = {
            title: "",
            build: [],
            detail: [],
            detailPrice: [],
            spell: [],
            startItem: [],
            startGold: 0,
            shoes: 0,
            rune: {},
            pickRate: 0,
            winRate: 0,
            games: 0,
            skill: [],
        }
        try {
            const item = coreList?.build_lst[coreSelected];
            const totalGames = coreList?.build_lst.reduce((sum, cur) => {
                sum += cur.games;
                return sum;
            }, 0);
            const totalStartGold = item?.start_item.build_price.reduce((sum, cur, index) => {
                const isSup = isSupItem(item.start_item.build[index]);
                if (!isSup) {
                    sum += cur;
                }
                return sum;
            }, 0);

            return {
                title: item.title,
                build: item.item.build,
                detail: item.item.detail,
                detailPrice: item.item.detail_price,
                spell: item.spell.build,
                startItem: item.start_item.build,
                startGold: totalStartGold,
                shoes: item.boots.item,
                rune: item.rune,
                pickRate: (item.pick_rate * 100).toFixed(1),
                winRate: (item.win_rate * 100).toFixed(1),
                games: item.games,
                skill: item.skill.detail,
            }

        } catch (e) {
            console.log(e)
            return {...obj}
        }
    }, [coreSelected, coreList, skillList]);


    if (coreList.length < 1 || skillList.spells.length < 1) return null;


    return (
        <BackgroundWrapper>
            <FlexBox alignItems={'flex-start'} height={'100%'} margin={'0 0 0 30px'}>
                <RuneListWrapper>
                    <TitleTag>{`[${translateTitle(selectedItem.title)}]`} {currentChampion?.champion_name} {t('championDetail.build')}</TitleTag>
                    <PickWrapper>
                        <PickText>{t('championDetailBuild.pickRate')}</PickText>
                        <PercentValueText>{selectedItem?.pickRate}<Percent>%</Percent></PercentValueText>
                        <GamesText>({threedigits(selectedItem.games)})</GamesText>
                        <PickBar/>
                        <PickText>{t('championDetailBuild.wr')}</PickText>
                        <ValueText value={selectedItem.winRate}>{selectedItem?.winRate}<Percent>%</Percent></ValueText>
                    </PickWrapper>
                    <ChampionsDetailBuildRuneBuild runeList={selectedItem.rune}/>
                </RuneListWrapper>
                <ItemBox>
                    <BuildItem title={`${t('championDetailBuild.build')}`}>
                        {selectedItem.build.map((data, index) => {
                            return (
                                <FlexBox key={index} alignItems={'flex-start'}>
                                    <ColumnBox alignItems={'center'}>
                                        <ImageItem itemId={Number(data)} width={42} height={42}/>
                                        <BuildText>{index + 1} {t('championDetailBuild.core')}</BuildText>
                                    </ColumnBox>
                                    {index + 1 !== selectedItem.build?.length && <Margin5>
                                        <img src={`${theme.icon.path}/icn_forward.svg`} width={14}
                                             height={14}/>
                                    </Margin5>}
                                </FlexBox>
                            )
                        })}
                    </BuildItem>

                    <FlexBox margin={'15px 0 0 0'}>
                        <BuildItem title={`${t('championDetailBuild.buildDetail')}`}>
                            <FlexBox alignItems={'flex-start'} padding={'3px 0 0 0'}>
                                <ColumnBox alignItems={'center'}>
                                    <FlexBox>
                                        <BuildStartItem list={selectedItem?.startItem} noSubItem={true}/>
                                    </FlexBox>
                                    <DetailText>{selectedItem.startGold}</DetailText>
                                </ColumnBox>
                                <ArrowBox>
                                    <img src={`${theme.icon.path}/icn_forward.svg`} width={14}
                                         height={14}/>
                                </ArrowBox>
                            </FlexBox>
                            {selectedItem.detail.map((data, index) => {
                                const isFinal = isFinalItem(data) || data === selectedItem?.shoes;
                                if (isFinal) {
                                    return (
                                        <FlexBox key={index} alignItems={'flex-start'}>
                                            <BoldBox alignItems={'center'}>
                                                <BoldBorderBox>
                                                    <ImageItem itemId={Number(data)} width={32} height={32}/>
                                                </BoldBorderBox>
                                                <DetailTextBold>{selectedItem.detailPrice[index]}</DetailTextBold>
                                            </BoldBox>
                                            {index + 1 !== selectedItem.detail?.length && <BorderArrowBox>
                                                <img src={`${theme.icon.path}/icn_forward.svg`} width={14}
                                                     height={14}/>
                                            </BorderArrowBox>}
                                        </FlexBox>
                                    )
                                }
                                return (
                                    <FlexBox key={index} alignItems={'flex-start'} padding={'3px 0 0 0'}>
                                        <ColumnBox alignItems={'center'}>
                                            <ImageItem itemId={Number(data)} width={32} height={32}/>
                                            <DetailText>{selectedItem.detailPrice[index]}</DetailText>
                                        </ColumnBox>
                                        {index + 1 !== selectedItem.detail?.length && <ArrowBox>
                                            <img src={`${theme.icon.path}/icn_forward.svg`} width={14}
                                                 height={14}/>
                                        </ArrowBox>}
                                    </FlexBox>
                                )
                            })}
                        </BuildItem>

                    </FlexBox>
                    <FlexBox margin={'15px 0 0 0'}>

                        <BuildItem
                            title={`${t('championDetailBuild.summonerSpells')}`}>
                            <FlexBox>
                                {selectedItem.spell.map((data, index) => {
                                    return (
                                        <SpellBox key={index} >
                                            <ImageSpell spellId={data} dataList={spellData} customVersion={currentVersion ? `${currentVersion}.1` : `${buildVersion[0]}.1`} />
                                        </SpellBox>
                                    )
                                })}
                            </FlexBox>
                        </BuildItem>

                        <MarginLeft50>
                            <BuildItem
                                title={`${t('championDetailBuild.starterBuild')}`}>
                                <FlexBox>
                                    <BuildStartItem list={selectedItem?.startItem}/>
                                </FlexBox>
                            </BuildItem>
                        </MarginLeft50>

                        <MarginLeft50>
                            <BuildItem
                                title={`${t('summoner.mastery.boots')}`}>
                                <FlexBox>
                                    <ImageItem itemId={selectedItem?.shoes} width={32} height={32}/>
                                </FlexBox>
                            </BuildItem>
                        </MarginLeft50>
                    </FlexBox>


                    <FlexBox margin={'15px 0 0 0'}>
                        <BuildItem title={`${t('championDetailBuild.skillBuild')}`}>
                            <BuildSkillSq list={selectedItem?.skill} skillList={skillList} champion_id={champId}/>
                        </BuildItem>
                    </FlexBox>
                </ItemBox>
            </FlexBox>
        </BackgroundWrapper>
    )
        ;
};

const BuildItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const ContentItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  .imgItem {
    border-radius: 4px;
  }

  .imgBorder {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 3px solid ${props => props.theme.color.green_100};
  }

  .imgDiv {
    border-radius: 4px;
  }

  .imgSpell {
    border-radius: 4px;
    width: 32px;
    height: 32px;
  }
`;
const BuildItem = ({
                       title, children
                   }) => {
    return (
        <BuildItemWrapper>
            <TitleTag>{title}</TitleTag>
            <ContentItem>
                {children}
            </ContentItem>
        </BuildItemWrapper>
    )
}

export default BuildRunes;