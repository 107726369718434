import React from 'react';
import styled, {useTheme} from "styled-components";
import {useHistory} from "react-router-dom";
import {useSuspenseMarqueeApi} from "../../common/hooks/api/useMarqueeAPI";
import useTimeHook from "../../common/hooks/useTimeHook";
import {openToSummonerMatch, openToSummonerStory} from "../../function";
import InfluIcon from "../summoner/Profile/InfluInfo/InfluIcon";
import useMarqueeDescription from "../../common/hooks/useMarqueeDescription";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1080px;
  align-items: center;
  margin-top: 22px;
`;
const ImageBox = styled.div`
`;

const ArrowBox = styled.div`
  cursor: pointer;
  margin-left: 8px;
`;
const MarqueeWrapper = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  flex: 1;
`;
const MarqueeScrollBox = styled.div`
  --item-size: ${props => props.dataLength};
  --time: calc(var(--item-size) * 2s);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  min-width: 100%;
  animation: scroll linear infinite normal;
  animation-duration: var(--time);
  min-height: 20px;


  &:hover {
    animation-play-state: paused;
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }
`;

const MarqueeContentText = styled.span`
  font-weight: 400;
  color: ${props => props.theme.color.dark_200};
  font-size: 12px;
  margin-left: 5px;
`;
const FullBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  top: 66px;
`;
const MarqueeBold = styled.span`
  font-weight: 600;
  color: ${props => props.theme.color.dark_100};
  font-size: 12px;
  margin-left: 4px;
`;
const Marquee = () => {
    const history = useHistory();
    const theme = useTheme();
    const {list} = useSuspenseMarqueeApi({limit: 10});


    if (!Array.isArray(list)) {
        return null
    }

    return (
        <FullBox>
            <Wrapper>
                <ImageBox>
                    <img src={'/images/icon_notifications_none.svg'} width={17} height={20} alt={""}/>
                </ImageBox>
                <MarqueeWrapper>
                    <MarqueeScrollBox dataLength={list.length * 2}>
                        <MarqueeList list={list}/>
                        <MarqueeList list={list}/>
                    </MarqueeScrollBox>
                </MarqueeWrapper>
                <ArrowBox onClick={() => history.push('/story')}>
                    <img src={`${theme.icon.path}/icon_news_arrow.svg`} width={13} height={13} alt={''}/>
                </ArrowBox>
            </Wrapper>
        </FullBox>
    );
};

const MarqueeList = ({list}) => {

    return list.map((data, index) => {
        return (
            <MarqueeItem data={data} marginRight={40} key={index}/>
        )
    })
}


const MarqueeContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-right: ${props => props.marginRight}px;

  &:hover {
    text-decoration: underline;
  }
`;

const MarqueeItem = ({data, marginRight}) => {
    const {lessMinWithIntl} = useTimeHook()
    const {transDescription} = useMarqueeDescription();
    return (
        <MarqueeContentWrapper onClick={() => {
            if (data.match_id) {
                openToSummonerMatch({
                    name: `${data.riot_name}-${data.riot_tag}`,
                    region: data.platform_id,
                    matchId: data.match_id
                })
            } else {
                openToSummonerStory({name: `${data.riot_name}-${data.riot_tag}`, region: data.platform_id})
            }
        }} marginRight={marginRight}>
            <InfluIcon status={data.status}/>
            <MarqueeBold>
                {`${data.team_al} ${data.name}`}
                <MarqueeContentText>
                    {transDescription(data)} ({lessMinWithIntl(data.start_timestamp * 1000,)})
                </MarqueeContentText>
            </MarqueeBold>
        </MarqueeContentWrapper>
    );
};

export default Marquee;