import React, {useRef} from 'react';

const ChartModalArrow = ({handleModalPrev, handleModalNext}) => {
    useRef();


    return (
        <div className="buttons" tabIndex={0} >
            <button className="prev" onClick={handleModalPrev}></button>
            <button className="next" onClick={handleModalNext}></button>
        </div>
    );
};

export default ChartModalArrow;
