import React from 'react';


const SmallInfluInfo = ({status,width = 14,height = 14}) => {

    if (status === 'pro') {
        return <img src={'/images/icon_small_pro.svg'} alt={""} width={width} height={height}/>
    }

    if (status === 'Streamer') {
        return <img src={'/images/icon_small_streamer.svg'} alt={""} width={width} height={height}/>
   }
    return status;
};

export default SmallInfluInfo;